


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';

import Aberdeen from '../Team-Kits-Images/Aberdeen.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Dundee from '../Team-Kits-Images/Dundee.png'
import DundeeUtd from '../Team-Kits-Images/DundeeUtd.png'
import Hearts from '../Team-Kits-Images/Hearts.png'
import Hibernian from '../Team-Kits-Images/Hibernian.png'
import Kilmarnock from '../Team-Kits-Images/Kilmarnock.png'
import Motherwell from '../Team-Kits-Images/Motherwell.png'
import Rangers from '../Team-Kits-Images/Rangers.png'
import RossCounty from '../Team-Kits-Images/RossCounty.png'
import StJohnstone from '../Team-Kits-Images/StJohnstone.png'
import StMirren from '../Team-Kits-Images/StMirren.png'
import { useTranslation } from 'react-i18next';



function ScottishFictures() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-07-03'), endDate: new Date('2024-08-06') },  // Matchweek 1
        { number: 2, startDate: new Date('2024-08-06'), endDate: new Date('2024-08-12') },  // Matchweek 2
        { number: 3, startDate: new Date('2024-08-12'), endDate: new Date('2024-08-26') },  // Matchweek 3
        { number: 4, startDate: new Date('2024-08-26'), endDate: new Date('2024-09-02') },  // Matchweek 4
        { number: 5, startDate: new Date('2024-09-02'), endDate: new Date('2024-09-16') },  // Matchweek 5
        { number: 6, startDate: new Date('2024-09-16'), endDate: new Date('2024-09-30') },  // Matchweek 6
        { number: 7, startDate: new Date('2024-09-30'), endDate: new Date('2024-10-07') },  // Matchweek 7
        { number: 8, startDate: new Date('2024-10-07'), endDate: new Date('2024-10-21') },  // Matchweek 8
        { number: 9, startDate: new Date('2024-10-21'), endDate: new Date('2024-10-28') },  // Matchweek 9
        { number: 10, startDate: new Date('2024-10-28'), endDate: new Date('2024-10-31') },  // Matchweek 10
        { number: 11, startDate: new Date('2024-10-31'), endDate: new Date('2024-11-04') },  // Matchweek 11
        { number: 12, startDate: new Date('2024-11-04'), endDate: new Date('2024-11-11') },  // Matchweek 12
        { number: 13, startDate: new Date('2024-11-11'), endDate: new Date('2024-11-24') },  // Matchweek 13
        { number: 14, startDate: new Date('2024-11-24'), endDate: new Date('2024-12-02') },  // Matchweek 14
        { number: 15, startDate: new Date('2024-12-02'), endDate: new Date('2024-12-05') },  // Matchweek 15
        { number: 16, startDate: new Date('2024-12-05'), endDate: new Date('2024-12-09') },  // Matchweek 16
        { number: 17, startDate: new Date('2024-12-09'), endDate: new Date('2024-12-16') },  // Matchweek 17
        { number: 18, startDate: new Date('2024-12-16'), endDate: new Date('2024-12-23') },  // Matchweek 18
        { number: 19, startDate: new Date('2024-12-23'), endDate: new Date('2024-12-27') },  // Matchweek 19
        { number: 20, startDate: new Date('2024-12-27'), endDate: new Date('2024-12-30') },  // Matchweek 20 // End of 2024
        { number: 21, startDate: new Date('2024-12-30'), endDate: new Date('2025-01-03') },  // Matchweek 21
        { number: 22, startDate: new Date('2025-01-03'), endDate: new Date('2025-01-10') },  // Matchweek 22
        { number: 23, startDate: new Date('2025-01-10'), endDate: new Date('2025-01-13') },  // Matchweek 23
        { number: 24, startDate: new Date('2025-01-13'), endDate: new Date('2025-01-26') },  // Matchweek 24
        { number: 25, startDate: new Date('2025-01-26'), endDate: new Date('2025-02-03') },  // Matchweek 25
        { number: 26, startDate: new Date('2025-02-03'), endDate: new Date('2025-02-16') },  // Matchweek 26
        { number: 27, startDate: new Date('2025-02-16'), endDate: new Date('2025-02-23') },  // Matchweek 27
        { number: 28, startDate: new Date('2025-02-23'), endDate: new Date('2025-02-27') },  // Matchweek 28
        { number: 29, startDate: new Date('2025-02-27'), endDate: new Date('2025-03-02') },  // Matchweek 29
        { number: 30, startDate: new Date('2025-03-02'), endDate: new Date('2025-03-16') },  // Matchweek 30
        { number: 31, startDate: new Date('2025-03-16'), endDate: new Date('2025-03-30') },  // Matchweek 31
        { number: 32, startDate: new Date('2025-03-30'), endDate: new Date('2025-04-06') },  // Matchweek 32
        { number: 33, startDate: new Date('2025-04-06'), endDate: new Date('2025-04-23') },  // Matchweek 33
        // Add more matchweeks as needed
    ];
    
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/179/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='App-headerV3'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="text-dark text-center padtop mb-3">SPFL Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Aberdeen' ? (
    <div className='Aberdeen'>
        <img src={Aberdeen} alt="Aberdeen" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.home.name === 'Celtic' ? (
    <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Heart Of Midlothian' ? (
    <img src={Hearts} alt="Heart Of Midlothian" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Motherwell' ? (
    <img src={Motherwell} alt="Motherwell" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Dundee' ? (
    <img src={Dundee} alt="Dundee" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Kilmarnock' ? (
    <img src={Kilmarnock} alt="Kilmarnock" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Rangers' ? (
    <img src={Rangers} alt="Rangers" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Hibernian' ? (
    <img src={Hibernian} alt="Hibernian" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'ST Johnstone' ? (
    <img src={StJohnstone} alt="ST Johnstone" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Ross County' ? (
    <img src={RossCounty} alt="Ross County" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'ST Mirren' ? (
    <img src={StMirren} alt="ST Mirren" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Dundee Utd' ? (
    <img src={DundeeUtd} alt="Dundee Utd" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {' '}
                                         {fixture.teams.away.name === 'Aberdeen' ? (
    <div className='Aberdeen'>
        <img src={Aberdeen} alt="Aberdeen" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.away.name === 'Celtic' ? (
    <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Heart Of Midlothian' ? (
    <img src={Hearts} alt="Heart Of Midlothian" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Motherwell' ? (
    <img src={Motherwell} alt="Motherwell" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Dundee' ? (
    <img src={Dundee} alt="Dundee" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Kilmarnock' ? (
    <img src={Kilmarnock} alt="Kilmarnock" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Rangers' ? (
    <img src={Rangers} alt="Rangers" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Hibernian' ? (
    <img src={Hibernian} alt="Hibernian" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'ST Johnstone' ? (
    <img src={StJohnstone} alt="ST Johnstone" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Ross County' ? (
    <img src={RossCounty} alt="Ross County" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'ST Mirren' ? (
    <img src={StMirren} alt="ST Mirren" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Dundee Utd' ? (
    <img src={DundeeUtd} alt="Dundee Utd" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                                                                                {fixture.teams.home.name === 'Celtic' ? (
    <h5>CEL</h5>
) : fixture.teams.home.name === 'Heart Of Midlothian' ? (
    <h5>HEA</h5>
) : fixture.teams.home.name === 'Motherwell' ? (
    <h5>MOT</h5>
) : fixture.teams.home.name === 'Dundee Utd' ? (
    <h5>DUU</h5>
) : fixture.teams.home.name === 'ST Mirren' ? (
    <h5>STM</h5>
) : fixture.teams.home.name === 'Kilmarnock' ? (
    <h5>KIL</h5>
) : fixture.teams.home.name === 'Hibernian' ? (
    <h5>HIB</h5>
) : fixture.teams.home.name === 'Dundee' ? (
    <h5>DUN</h5>
) : fixture.teams.home.name === 'Ross County' ? (
    <h5>ROS</h5>
) : fixture.teams.home.name === 'Rangers' ? (
    <h5>RAN</h5>
) : fixture.teams.home.name === 'Aberdeen' ? (
    <h5>ABE</h5>
) : fixture.teams.home.name === 'ST Johnstone' ? (
    <h5>STJ</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}


                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {' '}
                                                    {fixture.teams.away.name === 'Celtic' ? (
    <h5>CEL</h5>
) : fixture.teams.away.name === 'Heart Of Midlothian' ? (
    <h5>HEA</h5>
) : fixture.teams.away.name === 'Motherwell' ? (
    <h5>MOT</h5>
) : fixture.teams.away.name === 'Dundee Utd' ? (
    <h5>DUU</h5>
) : fixture.teams.away.name === 'ST Mirren' ? (
    <h5>STM</h5>
) : fixture.teams.away.name === 'Kilmarnock' ? (
    <h5>KIL</h5>
) : fixture.teams.away.name === 'Hibernian' ? (
    <h5>HIB</h5>
) : fixture.teams.away.name === 'Dundee' ? (
    <h5>DUN</h5>
) : fixture.teams.away.name === 'Ross County' ? (
    <h5>ROS</h5>
) : fixture.teams.away.name === 'Rangers' ? (
    <h5>RAN</h5>
) : fixture.teams.away.name === 'Aberdeen' ? (
    <h5>ABE</h5>
) : fixture.teams.away.name === 'ST Johnstone' ? (
    <h5>STJ</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
            <Footer />
        </div>
    );
}

export default ScottishFictures;
