import React , {useState, useEffect} from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { MdSportsSoccer } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import './HomePageTest.css'
function HomePageTest() {
  const { t } = useTranslation();
  const [authUser, setAuthUser] = useState(null);

  const cards = [
    {
      title: 'English Premier League',
      description: t('epl'),
      linkState: 'English Premier League',
      learnMore: '/epllearnmore',
    },
    {
      title: 'Scottish Premiership',
      description: 'The top 12 football clubs in Scotland make up the illustrious Scottish Premiership. Will Celtic defend their crown in the 24/25 season?',
      linkState: 'Scottish Premiership',
      learnMore: '/spfllearnmore',
    },
    {
      title: 'English Championship',
      description: 'With the English Championship\'s fierce competition, will Burnley hold their ground and secure promotion in the 24/25 season?',
      linkState: 'Championship',
      learnMore: '/championshiplearnmore',
    },
    {
      title: 'La Liga',
      description: 'With La Liga\'s fierce competition, will Atlético Madrid hold their ground and secure Champions League qualification in the 24/25 season?',
      linkState: 'La Liga',
      learnMore: '/laligalearnmore',
    },
    {
      title: 'Champions League',
      description: 'In the intense competition of the UEFA Champions League 24/25 season, will traditional powerhouses maintain their dominance?',
      linkState: 'UCL',
      learnMore: '/ucllearnmore',
    },
    {
      title: 'Bundesliga',
      description: 'Will traditional giants like Bayern Munich and Borussia Dortmund maintain their dominance in the Bundesliga 24/25 season?',
      linkState: 'Bundesliga',
      learnMore: '/bundesligalearnmore',
    },
    {
      title: 'Serie A',
      description: 'With the intense competition in the Serie A 24/25 season, will traditional powerhouses like Juventus maintain their dominance?',
      linkState: 'Serie A',
      learnMore: '/seriealearnmore',
    },
    {
      title: 'Premier Soccer League',
      description: 'Will traditional powerhouses like Mamelodi Sundowns maintain their dominance in the South African Premier Soccer League 24/25 season?',
      linkState: 'SASoccer',
      learnMore: '/sasoccerlearnmore',
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
    <Navbar />
    <div className='App-headerV8'>
      <section className="py-3 py-md-5 py-xl-8">
      <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center">Predictify</h2>
                <p className="text-secondary mb-2 text-center lead fs-4">Welcome to Predictify's Sports Predictor Game!</p>
                {authUser ? (
                  <div>
                    {authUser.emailVerified ? null : null}
                  </div>
                ) : (
                  <p className="text-secondary mb-5 text-center lead fs-4 d-flex justify-content-center">
                    <span className="text-secondary mb-2 lead fs-4 homehover" style={{marginRight: '5px'}}>
                      <Link className='homehover' to='/signup' style={{textDecoration: 'none', color: 'green'}}> Sign Up </Link>
                    </span>
                    now to join in the fun!
                  </p>
                )}
                <hr className="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle"/>
              </div>
            </div>
          </div>

        {/* Swiper Component */}
        <div className="container overflow-hidden" style={{marginTop: '100px'}}>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation
            loop={true}
            breakpoints={{
              640: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 30 },
              1024: { slidesPerView: 3, spaceBetween: 40 },
            }}
            className="mySwiper"
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <div className="text-center card-slide border border-" style={{borderRadius: '10px', overflow: 'hidden'}}>
                  <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <MdSportsSoccer size={58} style={{ color: 'purple' }} className='mb-4' />
                    <h4 className='text-dark'>{card.title}</h4>
                    <p>{card.description}</p>
                    <Link to='/joinhuddle' state={{ tournament: card.linkState }}>
                      <span className="btn bg-danger text-light">{t('playnow')}</span>
                    </Link>
                    <br />
                    <Link to={card.learnMore}>
                      <span className="btn text-dark" style={{paddingBottom: '5px'}}>{t('learnmore')}</span>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
    <Footer />
  </div>
  );
}

export default HomePageTest;
