


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import Freiburg from '../Team-Kits-Images/Freiburg.png'
import Frankfurt from '../Team-Kits-Images/Frankfurt.png'
import Berlin from '../Team-Kits-Images/Berlin.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Heidenheim from '../Team-Kits-Images/Heidenheim.png'
import Mainz from '../Team-Kits-Images/Mainz.png'
import Bremen from '../Team-Kits-Images/Bremen.png'
import Augsburg from '../Team-Kits-Images/Augsburg.png'
import Wolfsburg from '../Team-Kits-Images/Wolfsburg.png'
import Monch from '../Team-Kits-Images/Monch.png'
import Hoffenheim from '../Team-Kits-Images/Hoffenheim.png'
import Bochum from '../Team-Kits-Images/Bochum.png'
import Pauli from '../Team-Kits-Images/Pauli.png'
import Kiel from '../Team-Kits-Images/Kiel.png'
import { useTranslation } from 'react-i18next';



function BundesligaFixturesBracket() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-06-11'), endDate: new Date('2024-08-26') },
        { number: 2, startDate: new Date('2024-08-26'), endDate: new Date('2024-09-02') },
        { number: 3, startDate: new Date('2024-09-02'), endDate: new Date('2024-09-16') },
        { number: 4, startDate: new Date('2024-09-16'), endDate: new Date('2024-09-23') },
        { number: 5, startDate: new Date('2024-09-23'), endDate: new Date('2024-09-30') },
        { number: 6, startDate: new Date('2024-09-30'), endDate: new Date('2024-10-07') },
        { number: 7, startDate: new Date('2024-10-07'), endDate: new Date('2024-10-21') },
        { number: 8, startDate: new Date('2024-10-21'), endDate: new Date('2024-10-28') },
        { number: 9, startDate: new Date('2024-10-28'), endDate: new Date('2024-11-04') },
        { number: 10, startDate: new Date('2024-11-04'), endDate: new Date('2024-11-11') },
        { number: 11, startDate: new Date('2024-11-11'), endDate: new Date('2024-11-25') },
        { number: 12, startDate: new Date('2024-11-25'), endDate: new Date('2024-12-02') },
        { number: 13, startDate: new Date('2024-12-02'), endDate: new Date('2024-12-08') },
        { number: 14, startDate: new Date('2024-12-08'), endDate: new Date('2024-12-15') },
        { number: 15, startDate: new Date('2024-12-15'), endDate: new Date('2024-12-22') },
        { number: 16, startDate: new Date('2024-12-22'), endDate: new Date('2025-01-12') },
        { number: 17, startDate: new Date('2025-01-12'), endDate: new Date('2025-01-16') },
        { number: 18, startDate: new Date('2025-01-16'), endDate: new Date('2025-01-19') },
        { number: 19, startDate: new Date('2025-01-19'), endDate: new Date('2025-01-26') },
        { number: 20, startDate: new Date('2025-01-26'), endDate: new Date('2025-02-02') },
        { number: 21, startDate: new Date('2025-02-02'), endDate: new Date('2025-02-09') },
        { number: 22, startDate: new Date('2025-02-09'), endDate: new Date('2025-02-16') },
        { number: 23, startDate: new Date('2025-02-16'), endDate: new Date('2025-02-23') },
        { number: 24, startDate: new Date('2025-02-23'), endDate: new Date('2025-03-02') },
        { number: 25, startDate: new Date('2025-03-02'), endDate: new Date('2025-03-09') },
        { number: 26, startDate: new Date('2025-03-09'), endDate: new Date('2025-03-16') },
        { number: 27, startDate: new Date('2025-03-16'), endDate: new Date('2025-03-30') },
        { number: 28, startDate: new Date('2025-03-30'), endDate: new Date('2025-04-06') },
        { number: 29, startDate: new Date('2025-04-06'), endDate: new Date('2025-04-13') },
        { number: 30, startDate: new Date('2025-04-13'), endDate: new Date('2025-04-20') },
        { number: 31, startDate: new Date('2025-04-20'), endDate: new Date('2025-04-27') },
        { number: 32, startDate: new Date('2025-04-27'), endDate: new Date('2025-05-04') },
        { number: 33, startDate: new Date('2025-05-04'), endDate: new Date('2025-05-11') },
        { number: 34, startDate: new Date('2025-05-11'), endDate: new Date('2025-05-24') },
    ];
    
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className=''>
            <div className=''>
            <h2 className="text-dark text-center padtop mb-3">Bundesliga Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Bayern München' ? (
    <div className='Bayern'>
        <img src={BayernMunich} alt="Bayern München" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.home.name === 'Bayer Leverkusen' ? (
    <img src={Leverkusen} alt="Bayer Leverkusen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'SC Freiburg' ? (
    <img src={Freiburg} alt="SC Freiburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Eintracht Frankfurt' ? (
    <img src={Frankfurt} alt="Eintracht Frankfurt" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Union Berlin' ? (
    <img src={Berlin} alt="Union Berlin" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'RB Leipzig' ? (
    <img src={Leipzig} alt="RB Leipzig" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'VfB Stuttgart' ? (
    <img src={Stuttgart} alt="VfB Stuttgart" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Borussia Dortmund' ? (
    <img src={Dortmund} alt="Borussia Dortmund" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === '1. FC Heidenheim' ? (
    <img src={Heidenheim} alt="1. FC Heidenheim" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'FSV Mainz 05' ? (
    <img src={Mainz} alt="FSV Mainz 05" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Werder Bremen' ? (
    <img src={Bremen} alt="Werder Bremen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'FC Augsburg' ? (
    <img src={Augsburg} alt="FC Augsburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'VfL Wolfsburg' ? (
    <img src={Wolfsburg} alt="VfL Wolfsburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Borussia Mönchengladbach' ? (
    <img src={Monch} alt="Borussia Mönchengladbach" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === '1899 Hoffenheim' ? (
    <img src={Hoffenheim} alt="1899 Hoffenheim" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'VfL Bochum' ? (
    <img src={Bochum} alt="VfL Bochum" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'FC St. Pauli' ? (
    <img src={Pauli} alt="FC St. Pauli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Holstein Kiel' ? (
    <img src={Kiel} alt="Holstein Kiel" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {fixture.teams.away.name === 'Bayern München' ? (
    <div className='Bayern'>
        <img src={BayernMunich} alt="Bayern München" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.away.name === 'Bayer Leverkusen' ? (
    <img src={Leverkusen} alt="Bayer Leverkusen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'SC Freiburg' ? (
    <img src={Freiburg} alt="SC Freiburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Eintracht Frankfurt' ? (
    <img src={Frankfurt} alt="Eintracht Frankfurt" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Union Berlin' ? (
    <img src={Berlin} alt="Union Berlin" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'RB Leipzig' ? (
    <img src={Leipzig} alt="RB Leipzig" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'VfB Stuttgart' ? (
    <img src={Stuttgart} alt="VfB Stuttgart" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Borussia Dortmund' ? (
    <img src={Dortmund} alt="Borussia Dortmund" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === '1. FC Heidenheim' ? (
    <img src={Heidenheim} alt="1. FC Heidenheim" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'FSV Mainz 05' ? (
    <img src={Mainz} alt="FSV Mainz 05" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Werder Bremen' ? (
    <img src={Bremen} alt="Werder Bremen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'FC Augsburg' ? (
    <img src={Augsburg} alt="FC Augsburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'VfL Wolfsburg' ? (
    <img src={Wolfsburg} alt="VfL Wolfsburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Borussia Mönchengladbach' ? (
    <img src={Monch} alt="Borussia Mönchengladbach" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === '1899 Hoffenheim' ? (
    <img src={Hoffenheim} alt="1899 Hoffenheim" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'VfL Bochum' ? (
    <img src={Bochum} alt="VfL Bochum" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'FC St. Pauli' ? (
    <img src={Pauli} alt="FC St. Pauli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Holstein Kiel' ? (
    <img src={Kiel} alt="Holstein Kiel" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                                                                           {fixture.teams.home.name === 'Bayern München' ? (
    <h5>FCB</h5>
) : fixture.teams.home.name === 'Bayer Leverkusen' ? (
    <h5>BAY</h5>
) : fixture.teams.home.name === 'SC Freiburg' ? (
    <h5>FRI</h5>
) : fixture.teams.home.name === 'Eintracht Frankfurt' ? (
    <h5>FRA</h5>
) : fixture.teams.home.name === 'Union Berlin' ? (
    <h5>UB</h5>
) : fixture.teams.home.name === 'RB Leipzig' ? (
    <h5>RBL</h5>
) : fixture.teams.home.name === 'VfB Stuttgart' ? (
    <h5>STU</h5>
) : fixture.teams.home.name === 'Borussia Dortmund' ? (
    <h5>BVB</h5>
) : fixture.teams.home.name === '1. FC Heidenheim' ? (
    <h5>HEI</h5>
) : fixture.teams.home.name === 'FSV Mainz 05' ? (
    <h5>MAI</h5>
) : fixture.teams.home.name === 'Werder Bremen' ? (
    <h5>BRE</h5>
) : fixture.teams.home.name === 'FC Augsburg' ? (
    <h5>AUG</h5>
) : fixture.teams.home.name === 'VfL Wolfsburg' ? (
    <h5>WOL</h5>
) : fixture.teams.home.name === 'Borussia Mönchengladbach' ? (
    <h5>MÖN</h5>
) : fixture.teams.home.name === '1899 Hoffenheim' ? (
    <h5>HOF</h5>
) : fixture.teams.home.name === 'VfL Bochum' ? (
    <h5>BOC</h5>
) : fixture.teams.home.name === 'FC St. Pauli' ? (
    <h5>STP</h5>
) : fixture.teams.home.name === 'Holstein Kiel' ? (
    <h5>KIE</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}


                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {fixture.teams.away.name === 'Bayern München' ? (
    <h5>FCB</h5>
) : fixture.teams.away.name === 'Bayer Leverkusen' ? (
    <h5>BAY</h5>
) : fixture.teams.away.name === 'SC Freiburg' ? (
    <h5>FRI</h5>
) : fixture.teams.away.name === 'Eintracht Frankfurt' ? (
    <h5>FRA</h5>
) : fixture.teams.away.name === 'Union Berlin' ? (
    <h5>UB</h5>
) : fixture.teams.away.name === 'RB Leipzig' ? (
    <h5>RBL</h5>
) : fixture.teams.away.name === 'VfB Stuttgart' ? (
    <h5>STU</h5>
) : fixture.teams.away.name === 'Borussia Dortmund' ? (
    <h5>BVB</h5>
) : fixture.teams.away.name === '1. FC Heidenheim' ? (
    <h5>HEI</h5>
) : fixture.teams.away.name === 'FSV Mainz 05' ? (
    <h5>MAI</h5>
) : fixture.teams.away.name === 'Werder Bremen' ? (
    <h5>BRE</h5>
) : fixture.teams.away.name === 'FC Augsburg' ? (
    <h5>AUG</h5>
) : fixture.teams.away.name === 'VfL Wolfsburg' ? (
    <h5>WOL</h5>
) : fixture.teams.away.name === 'Borussia Mönchengladbach' ? (
    <h5>MÖN</h5>
) : fixture.teams.away.name === '1899 Hoffenheim' ? (
    <h5>HOF</h5>
) : fixture.teams.away.name === 'VfL Bochum' ? (
    <h5>BOC</h5>
) : fixture.teams.away.name === 'FC St. Pauli' ? (
    <h5>STP</h5>
) : fixture.teams.away.name === 'Holstein Kiel' ? (
    <h5>KIE</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
        </div>
    );
}

export default BundesligaFixturesBracket;
