
import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import { runTransaction } from 'firebase/firestore'

import { useTranslation } from 'react-i18next';


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import ChampionshipStandingsPicks from './ChampionshipStandingsPicks'
 
function ChampionshipPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');

  const fetchTotalPoints = async (displayName) => { 
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `Championship2024TotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
      console.log("Fetched total points:", totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `Championship2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `Championship2024TotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
      console.log("Fetched picks:", userPicks);
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-07-03', end: '2024-08-13' },  // Matchweek 1
    { start: '2024-08-13', end: '2024-08-19' },  // Matchweek 2
    { start: '2024-08-19', end: '2024-08-26' },  // Matchweek 3
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 6
    { start: '2024-09-23', end: '2024-09-30' },  // Matchweek 7
    { start: '2024-09-30', end: '2024-10-03' },  // Matchweek 8
    { start: '2024-10-03', end: '2024-10-07' },  // Matchweek 9
    { start: '2024-10-07', end: '2024-10-21' },  // Matchweek 10
    { start: '2024-10-21', end: '2024-10-24' },  // Matchweek 11
    { start: '2024-10-24', end: '2024-10-28' },  // Matchweek 12
    { start: '2024-10-28', end: '2024-11-04' },  // Matchweek 13
    { start: '2024-11-04', end: '2024-11-08' },  // Matchweek 14
    { start: '2024-11-08', end: '2024-11-11' },  // Matchweek 15
    { start: '2024-11-11', end: '2024-11-25' },  // Matchweek 16
    { start: '2024-11-25', end: '2024-11-28' },  // Matchweek 17
    { start: '2024-11-28', end: '2024-12-02' },  // Matchweek 18
    { start: '2024-12-02', end: '2024-12-09' },  // Matchweek 19
    { start: '2024-12-09', end: '2024-12-12' },  // Matchweek 20
    { start: '2024-12-12', end: '2024-12-16' },  // Matchweek 21
    { start: '2024-12-16', end: '2024-12-23' },  // Matchweek 22
    { start: '2024-12-23', end: '2024-12-27' },  // Matchweek 23
    { start: '2024-12-27', end: '2024-12-30' },  // Matchweek 24
    { start: '2024-12-30', end: '2025-01-02' },  // Matchweek 25 2025 
    { start: '2025-01-01', end: '2025-01-07' },  // Matchweek 26
    { start: '2025-01-07', end: '2025-01-19' },  // Matchweek 27
    { start: '2025-01-19', end: '2025-01-23' },  // Matchweek 28
    { start: '2025-01-23', end: '2025-01-26' },  // Matchweek 29
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 30
    { start: '2025-02-02', end: '2025-02-09' },  // Matchweek 31
    { start: '2025-02-09', end: '2025-02-13' },  // Matchweek 32
    { start: '2025-02-13', end: '2025-02-16' },  // Matchweek 33
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 34
    { start: '2025-02-23', end: '2025-03-02' },  // Matchweek 35
    { start: '2025-03-02', end: '2025-03-09' },  // Matchweek 36
    { start: '2025-03-09', end: '2025-03-13' },  // Matchweek 37
    { start: '2025-03-13', end: '2025-03-16' },  // Matchweek 38
    { start: '2025-03-16', end: '2025-03-30' },  // Matchweek 39
    { start: '2025-03-30', end: '2025-04-06' },  // Matchweek 40
    { start: '2025-04-06', end: '2025-04-10' },  // Matchweek 41
    { start: '2025-04-10', end: '2025-04-13' },  // Matchweek 42
    { start: '2025-04-13', end: '2025-04-19' },  // Matchweek 43
    { start: '2025-04-19', end: '2025-04-22' },  // Matchweek 44
    { start: '2025-04-22', end: '2025-04-27' },  // Matchweek 45
    { start: '2025-04-27', end: '2025-05-23' }   // Matchweek 46
]);


useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  

  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
        console.log("Fetched fixtures:", fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };
  
    fetchFixtures();
  }, []);



// Function to award points for a specific user's fixture
const awardPointsForUser = async (userDisplayName, fixtureId) => {
  const fixture = fixtures.find(f => f.fixture.id === parseInt(fixtureId));
  const userPrediction = inputsData[fixtureId];

  if (fixture && userPrediction && (fixture.fixture.status.short === 'FT' || fixture.fixture.status.short === 'AET') && !userPrediction.pointsAwarded) {
      const actualHomeScore = fixture.goals.home;
      const actualAwayScore = fixture.goals.away;
      const predictedHomeScore = parseInt(userPrediction.home, 10);
      const predictedAwayScore = parseInt(userPrediction.away, 10);

      console.log(`Checking fixture ${fixtureId}: actual (${actualHomeScore}, ${actualAwayScore}), predicted (${predictedHomeScore}, ${predictedAwayScore})`);

      let pointsToAward = 0;

      // Check for exact score match
      if (predictedHomeScore === actualHomeScore && predictedAwayScore === actualAwayScore) {
          pointsToAward = 3; // Award 3 points for an exact match
      } else {
          // Determine the actual outcome
          let actualOutcome;
          if (actualHomeScore > actualAwayScore) {
              actualOutcome = 'home'; // Home team wins
          } else if (actualHomeScore < actualAwayScore) {
              actualOutcome = 'away'; // Away team wins
          } else {
              actualOutcome = 'draw'; // It's a draw
          }

          // Determine the predicted outcome
          let predictedOutcome;
          if (predictedHomeScore > predictedAwayScore) {
              predictedOutcome = 'home'; // User predicted home win
          } else if (predictedHomeScore < predictedAwayScore) {
              predictedOutcome = 'away'; // User predicted away win
          } else {
              predictedOutcome = 'draw'; // User predicted a draw
          }

          // Award points for correct outcome (1 point)
          if (actualOutcome === predictedOutcome) {
              pointsToAward = 1; // Award 1 point for correct outcome
          }
      }

      if (pointsToAward > 0) {
          await updatePoints(userDisplayName, fixtureId, pointsToAward);
      }
  }
};

const fetchFixtureData = async (fixtureId) => {
  const apiUrl = `https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024`;
  const response = await fetch(`${apiUrl}/${fixtureId}`);
  const data = await response.json();
  return data;
};

const determineOutcome = (homeGoals, awayGoals) => {
  if (homeGoals > awayGoals) {
    return 'homeWin';
  } else if (homeGoals < awayGoals) {
    return 'awayWin';
  } else {
    return 'draw';
  }
};


const updatePoints = async (displayName, fixtureId, userPrediction) => {
  const db = getDatabase();
  const userRef = ref(db, `Championship2024Picks/${displayName}/${fixtureId}`);
  const totalPointsRef = ref(db, `Championship2024TotalPoints/${displayName}`);

  try {
    // Fetch the actual fixture data
    const fixtureData = await fetchFixtureData(fixtureId);
    const actualScore = fixtureData.score.fulltime;

    // Determine the outcomes
    const userOutcome = determineOutcome(userPrediction.home, userPrediction.away);
    const actualOutcome = determineOutcome(actualScore.home, actualScore.away);

    // Determine the points to award
    let points = 0;
    if (
      userPrediction.home === actualScore.home &&
      userPrediction.away === actualScore.away
    ) {
      points = 3; // Exact score match
    } else if (userOutcome === actualOutcome) {
      points = 1; // Correct outcome but wrong score
    } else {
      points = 0; // Incorrect outcome
    }

    // Update the user's total points in the database
    await runTransaction(totalPointsRef, (currentTotalPoints) => {
      if (currentTotalPoints === null) {
        return { totalPoints: points };
      } else {
        return { totalPoints: currentTotalPoints.totalPoints + points };
      }
    });

    // Update the user's points for the specific fixture in the database
    await runTransaction(userRef, (currentFixtureData) => {
      if (currentFixtureData === null) {
        return { points: points, pointsAwarded: true };
      } else {
        return { ...currentFixtureData, points: points, pointsAwarded: true };
      }
    });
  } catch (error) {
    console.error('Error updating points:', error);
  }
};




// Fetch picks and award points for all users
const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const picksRef = ref(db, `Chamoionship2024Picks`);

    const picksSnapshot = await get(picksRef);
    const picksData = picksSnapshot.val() || {};

    for (const userDisplayName in picksData) {
      if (picksData.hasOwnProperty(userDisplayName)) {
        const userPicks = picksData[userDisplayName];
        if (userPicks[fixtureId]) {
          await awardPointsForUser(userDisplayName, fixtureId);
        }
      }
    }
  } catch (error) {
    console.error('Error awarding points for all users:', error);
  }
};
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const startPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
  }

  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000);
};

const handleInputChange = (fixtureId, team, value) => {
  setInputsData(prevInputsData => ({
    ...prevInputsData,
    [fixtureId]: {
      ...prevInputsData[fixtureId],
      [team]: value,

    }
  }));
};

const handleSubmit = async () => {
  if (userId) {
    const db = getDatabase();
    const picksRef = ref(db, `Championship2024Picks/${displayName}`);
    const totalPointsRef = ref(db, `Championship2024TotalPoints/${displayName}`);

    let userDisplayName = user.displayName || 'Anonymous';

    // Extract user ID within parentheses
    const match = userDisplayName.match(/\(([^)]+)\)/);
    if (match && match[1]) {
      userDisplayName = match[1];
    }

    try {
      // Fetch existing picks from the backend
      const picksSnapshot = await get(picksRef);
      const existingPicksData = picksSnapshot.val() || {};

      // Debug: Log the current inputsData before filtering
      console.log("inputsData before filtering:", inputsData);

      // Filter inputsData to only include fixtures where a valid prediction is made
      const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
        const fixturePrediction = inputsData[fixtureId];

        // Check if home or away prediction is made (0 is valid, undefined is not)
        if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
          acc[fixtureId] = {
            home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
            away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
          };
        }
        return acc;
      }, {});

      // Debug: Log the filtered inputsData after filtering
      console.log("filteredInputsData:", filteredInputsData);

      // Check if any predictions are available for submission
      if (Object.keys(filteredInputsData).length === 0) {
        console.log("No predictions made, nothing to submit.");
        return;  // Exit early if there are no valid predictions
      }

      // Merge new filtered picks with existing picks
      const mergedPicks = {
        ...existingPicksData,
        ...filteredInputsData
      };

      // Debug: Log the merged data being sent to the backend
      console.log("Merged picks data being submitted:", mergedPicks);

      // Submit the merged picks to the backend
      await set(picksRef, mergedPicks);

      // Check if totalPoints entry exists, if not, initialize it
      const totalPointsSnapshot = await get(totalPointsRef);
      if (!totalPointsSnapshot.exists()) {
        await set(totalPointsRef, { totalPoints: 0 });
      }

      setSent(true);
      setTimeout(() => setSent(false), 15000);
      setConfetti(true);
      setTimeout(() => setConfetti(false), 5000);

      // Start points incrementing for all fixtures with a prediction
      Object.keys(filteredInputsData).forEach(fixtureId => {
        startPointsIncrement(fixtureId);
      });
    } catch (error) {
      console.error('Error submitting picks:', error);
      setError(true);
      setTimeout(() => setError(false), 5000);
    }
  }
};

  

  // The rest of the code remains unchanged.
  
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  
  const totalPointsa = useMemo(() => {
    return Object.values(inputsData).reduce((acc, input) => {
      return acc + (input.points || 0);
    }, 0);
  }, [inputsData]);

  const teamInitials = {
    'Blackburn': 'BLB',
    'Bristol City': 'BRC',
    'Cardiff': 'CAR',
    'Coventry': 'COV',
    'Hull City': 'HUL',
    'Middlesbrough': 'MID',
    'Millwall': 'MIL',
    'Norwich': 'NOR',
    'Plymouth': 'PLY',
    'Portsmouth': 'POR',
    'Preston': 'PRE',
    'QPR': 'QPR',
    'Sheffield Wednesday': 'SHW',
    'Stoke': 'STO',
    'Sunderland': 'SUN',
    'Swansea': 'SWA',
    'Watford': 'WAT',
    'West Brom': 'WBA',
    'Leeds': 'LEE',
    'Derby': 'DER',
    'Sheffield Utd': 'SHU',
    'Oxford United': 'OXF',
    'Luton': 'LUT',
    'Burnley': 'BUR'
};

  
  

  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const teamLogos = {
    'Blackburn': Blackburn,
    'Bristol City': Bristol,
    'Cardiff': Cardiff,
    'Coventry': Coventry,
    'Hull City': Hull,
    'Middlesbrough': Middlesbrough,
    'Millwall': Millwall,
    'Norwich': Norwich,
    'Plymouth': Plymouth,
    'Portsmouth': Portsmouth,
    'Preston': Preston,
    'QPR': QPR,
    'Sheffield Wednesday': Wednesday,
    'Stoke': Stoke,
    'Sunderland': Sunderland,
    'Swansea': Swansea,
    'Watford': Watford,
    'West Brom': WestBrom,
    'Leeds': Leeds,
    'Derby': Derby,
    'Sheffield Utd': SheffieldUnited,
    'Oxford United': Oxford,
    'Stoke City': Stoke,
    'Luton': Luton,
    'Burnley': Burnley,




  };
  

const renderTeamLogo = (teamName) => {
const logo = teamLogos[teamName];
if (logo) {
  return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
}
return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
};
  

  return (
<div>
  <Navbar />
  <div className='App-headerV3'>
    <h2 className='text-dark text-center padtop'>Championship Picks</h2>
    <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
      <div className="dropdown d-inline-block">
        <button
          className="btn btn-purple dropdown-toggle"
          type="button"
          id="groupStageDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectedMatchday}
        </button>
        <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
          <ul className="list-group">
            {dateRanges.map((range, index) => (
              <li key={index} className="list-group-item">
                <button className="dropdown-item" onClick={() => handlePageChange(index + 1)}>
                  Round {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

    <div className='container'>
      <div className='row'>
        {/* Standings Card for large screens */}
        <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin' style={{ paddingTop: '15px' }}>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'><ChampionshipStandingsPicks /></h5>
            </div>
          </div>
        </div>

        {/* Main content area */}
        <div className='col-lg-7 col-12'>
          {filteredFixtures.map(fixture => {
            // Get the predicted scores from user input
            const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
            const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
            const actualHomeGoals = fixture.goals.home;
            const actualAwayGoals = fixture.goals.away;

            let points = 0;

            // Check if the user made predictions and the match is finished
            if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
              if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                points = 3; // Exact score
              } else if (
                (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
              ) {
                points = 1; // Correct outcome but wrong score
              }
            }

            return (
              <div key={fixture.fixture.id} className='my-3 card'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
                      <div className='form-group border-secondary text-center'>
                        <select
                          value={inputsData[fixture.fixture.id]?.home ?? ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (!isNaN(value) && value >= 0 && value <= 10) {
                              handleInputChange(fixture.fixture.id, 'home', value);
                            } else {
                              handleInputChange(fixture.fixture.id, 'home', '');
                            }
                          }}
                          className='form-control text-center border-secondary'
                          disabled={fixture.fixture.status.short !== 'NS'}
                          style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
                        >
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
                      <div className='form-group border-secondary text-center'>
                        <select
                          value={inputsData[fixture.fixture.id]?.away ?? ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (!isNaN(value) && value >= 0 && value <= 10) {
                              handleInputChange(fixture.fixture.id, 'away', value);
                            } else {
                              handleInputChange(fixture.fixture.id, 'away', '');
                            }
                          }}
                          className='form-control text-center border-secondary'
                          disabled={fixture.fixture.status.short !== 'NS'}
                          style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
                        >
                          <option value="">Pick</option>
                          {[...Array(11).keys()].map(num => (
                            <option key={num} value={num}>{num}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  <div>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
                  <p className="text-center">{convertToFormattedDateTime(fixture.fixture.date)}</p>

                  {/* Display the points scored for this fixture */}
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : ''}>
                      You scored <b>{points}</b> {points === 1 ? 'point' : 'points'} for this game
                    </p>
                  )}
                </div>
              </div>
            );
          })}

          <div className='text-center' style={{ paddingBottom: '10px' }}>
            <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
            {confetti && <Confetti />}
          </div>

          <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
            <p>Total points: <b>{totalPoints}</b></p>
          </div>

          {sent && (
            <>
              <div className='alert alert-success Nav-HeaderV8 text-center'>
                Your picks have been sent!
              </div>
              {confetti && <Confetti gravity={-5.5} />}
            </>
          )}
          {error && <p className='text-danger'>Error submitting picks!</p>}
        </div>

        {/* Standings Card for small screens */}
        <div className='col-12 d-lg-none mb-3' style={{ marginTop: '15px' }}>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'><ChampionshipStandingsPicks /></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</div>

  );
}

export default ChampionshipPicks;
