


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Torino from '../Team-Kits-Images/Torino.png'
import Napoli from '../Team-Kits-Images/Napoli.png'
import Udinese from '../Team-Kits-Images/Udinese.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Empoli from '../Team-Kits-Images/Empoli.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Lazio from '../Team-Kits-Images/Lazio.png'
import Roma from '../Team-Kits-Images/Roma.png'
import Verona from '../Team-Kits-Images/Verona.png'
import Fiorentina from '../Team-Kits-Images/Fiorentina.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Parma from '../Team-Kits-Images/Parma.png'
import Como from '../Team-Kits-Images/Com.png'
import Genoa from '../Team-Kits-Images/Genoa.png'
import Lecce from '../Team-Kits-Images/Lecce.png'
import Venezia from '../Team-Kits-Images/Venezia.png'
import Monza from '../Team-Kits-Images/Monza.png'
import Cagliari from '../Team-Kits-Images/Cagliari.png'
import { useTranslation } from 'react-i18next';



function SerieAFixtures() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-06-11'), endDate: new Date('2024-08-20') },  // Matchweek 1
        { number: 2, startDate: new Date('2024-08-20'), endDate: new Date('2024-08-27') },  // Matchweek 2
        { number: 3, startDate: new Date('2024-08-27'), endDate: new Date('2024-09-02') },  // Matchweek 3
        { number: 4, startDate: new Date('2024-09-02'), endDate: new Date('2024-09-17') },  // Matchweek 4
        { number: 5, startDate: new Date('2024-09-17'), endDate: new Date('2024-09-25') },  // Matchweek 5
        { number: 6, startDate: new Date('2024-09-25'), endDate: new Date('2024-10-01') },  // Matchweek 6
        { number: 7, startDate: new Date('2024-10-01'), endDate: new Date('2024-10-07') },  // Matchweek 7
        { number: 8, startDate: new Date('2024-10-07'), endDate: new Date('2024-10-22') },  // Matchweek 8
        { number: 9, startDate: new Date('2024-10-22'), endDate: new Date('2024-10-28') },  // Matchweek 9
        { number: 10, startDate: new Date('2024-10-28'), endDate: new Date('2024-11-01') },  // Matchweek 10
        { number: 11, startDate: new Date('2024-11-01'), endDate: new Date('2024-11-05') },  // Matchweek 11
        { number: 12, startDate: new Date('2024-11-05'), endDate: new Date('2024-11-11') },  // Matchweek 12
        { number: 13, startDate: new Date('2024-11-11'), endDate: new Date('2024-11-26') },  // Matchweek 13
        { number: 14, startDate: new Date('2024-11-26'), endDate: new Date('2024-12-02') },  // Matchweek 14
        { number: 15, startDate: new Date('2024-12-02'), endDate: new Date('2024-12-09') },  // Matchweek 15
        { number: 16, startDate: new Date('2024-12-09'), endDate: new Date('2024-12-16') },  // Matchweek 16
        { number: 17, startDate: new Date('2024-12-16'), endDate: new Date('2024-12-23') },  // Matchweek 17
        { number: 18, startDate: new Date('2024-12-23'), endDate: new Date('2024-12-30') },  // Matchweek 18
        { number: 19, startDate: new Date('2024-12-30'), endDate: new Date('2025-01-06') },  // Matchweek 19
        { number: 20, startDate: new Date('2025-01-06'), endDate: new Date('2025-01-13') },  // Matchweek 20
        { number: 21, startDate: new Date('2025-01-13'), endDate: new Date('2025-01-20') },  // Matchweek 21
        { number: 22, startDate: new Date('2025-01-20'), endDate: new Date('2025-01-27') },  // Matchweek 22
        { number: 23, startDate: new Date('2025-01-27'), endDate: new Date('2025-02-03') },  // Matchweek 23
        { number: 24, startDate: new Date('2025-02-03'), endDate: new Date('2025-02-10') },  // Matchweek 24
        { number: 25, startDate: new Date('2025-02-10'), endDate: new Date('2025-02-17') },  // Matchweek 25
        { number: 26, startDate: new Date('2025-02-17'), endDate: new Date('2025-02-24') },  // Matchweek 26
        { number: 27, startDate: new Date('2025-02-24'), endDate: new Date('2025-03-03') },  // Matchweek 27
        { number: 28, startDate: new Date('2025-03-03'), endDate: new Date('2025-03-10') },  // Matchweek 28
        { number: 29, startDate: new Date('2025-03-10'), endDate: new Date('2025-03-17') },  // Matchweek 29
        { number: 30, startDate: new Date('2025-03-17'), endDate: new Date('2025-04-01') },  // Matchweek 30
        { number: 31, startDate: new Date('2025-04-01'), endDate: new Date('2025-04-07') },  // Matchweek 31
        { number: 32, startDate: new Date('2025-04-07'), endDate: new Date('2025-04-14') },  // Matchweek 32
        { number: 33, startDate: new Date('2025-04-14'), endDate: new Date('2025-04-21') },  // Matchweek 33
        { number: 34, startDate: new Date('2025-04-21'), endDate: new Date('2025-04-28') },  // Matchweek 34
        { number: 35, startDate: new Date('2025-04-28'), endDate: new Date('2025-05-05') },  // Matchweek 35
        { number: 36, startDate: new Date('2025-05-05'), endDate: new Date('2025-05-12') },  // Matchweek 36
        { number: 37, startDate: new Date('2025-05-12'), endDate: new Date('2025-05-19') },  // Matchweek 37
        { number: 38, startDate: new Date('2025-05-19'), endDate: new Date('2025-07-20') },  // Matchweek 38
    ];
    
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='App-headerV3'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="text-dark text-center padtop mb-3">Serie A Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Torino' ? (
    <div className='Torino'>
        <img src={Torino} alt="Torino" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.home.name === 'Napoli' ? (
    <img src={Napoli} alt="Napoli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Udinese' ? (
    <img src={Udinese} alt="Udinese" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Juventus' ? (
    <img src={Juventus} alt="Juventus" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Empoli' ? (
    <img src={Empoli} alt="Empoli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Inter' ? (
    <img src={Inter} alt="Inter" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'AC Milan' ? (
    <img src={Milan} alt="AC Milan" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Lazio' ? (
    <img src={Lazio} alt="Lazio" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'AS Roma' ? (
    <img src={Roma} alt="AS Roma" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Verona' ? (
    <img src={Verona} alt="Verona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Fiorentina' ? (
    <img src={Fiorentina} alt="Fiorentina" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Atalanta' ? (
    <img src={Atalanta} alt="Atalanta" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Bologna' ? (
    <img src={Bologna} alt="Bologna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Parma' ? (
    <img src={Parma} alt="Parma" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Como' ? (
    <img src={Como} alt="Como" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Genoa' ? (
    <img src={Genoa} alt="Genoa" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Lecce' ? (
    <img src={Lecce} alt="Lecce" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Venezia' ? (
    <img src={Venezia} alt="Venezia" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Monza' ? (
    <img src={Monza} alt="Monza" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Cagliari' ? (
    <img src={Cagliari} alt="Cagliari" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {fixture.teams.away.name === 'Torino' ? (
    <div className='Torino'>
        <img src={Torino} alt="Torino" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.away.name === 'Napoli' ? (
    <img src={Napoli} alt="Napoli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Udinese' ? (
    <img src={Udinese} alt="Udinese" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Juventus' ? (
    <img src={Juventus} alt="Juventus" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Empoli' ? (
    <img src={Empoli} alt="Empoli" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Inter' ? (
    <img src={Inter} alt="Inter" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'AC Milan' ? (
    <img src={Milan} alt="AC Milan" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Lazio' ? (
    <img src={Lazio} alt="Lazio" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'AS Roma' ? (
    <img src={Roma} alt="AS Roma" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Verona' ? (
    <img src={Verona} alt="Verona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Fiorentina' ? (
    <img src={Fiorentina} alt="Fiorentina" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Atalanta' ? (
    <img src={Atalanta} alt="Atalanta" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Bologna' ? (
    <img src={Bologna} alt="Bologna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Parma' ? (
    <img src={Parma} alt="Parma" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Como' ? (
    <img src={Como} alt="Como" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Genoa' ? (
    <img src={Genoa} alt="Genoa" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Lecce' ? (
    <img src={Lecce} alt="Lecce" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Venezia' ? (
    <img src={Venezia} alt="Venezia" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Monza' ? (
    <img src={Monza} alt="Monza" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Cagliari' ? (
    <img src={Cagliari} alt="Cagliari" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}


                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}
                                                                                                                {fixture.teams.home.name === 'Torino' ? (
    <h5>TOR</h5>
) : fixture.teams.home.name === 'Napoli' ? (
    <h5>NAP</h5>
) : fixture.teams.home.name === 'Udinese' ? (
    <h5>UDI</h5>
) : fixture.teams.home.name === 'Juventus' ? (
    <h5>JUV</h5>
) : fixture.teams.home.name === 'Empoli' ? (
    <h5>EMP</h5>
) : fixture.teams.home.name === 'Inter' ? (
    <h5>INT</h5>
) : fixture.teams.home.name === 'AC Milan' ? (
    <h5>ACM</h5>
) : fixture.teams.home.name === 'Lazio' ? (
    <h5>LAZ</h5>
) : fixture.teams.home.name === 'AS Roma' ? (
    <h5>ROM</h5>
) : fixture.teams.home.name === 'Verona' ? (
    <h5>VER</h5>
) : fixture.teams.home.name === 'Fiorentina' ? (
    <h5>FIO</h5>
) : fixture.teams.home.name === 'Atalanta' ? (
    <h5>ATA</h5>
) : fixture.teams.home.name === 'Bologna' ? (
    <h5>BOL</h5>
) : fixture.teams.home.name === 'Parma' ? (
    <h5>PAR</h5>
) : fixture.teams.home.name === 'Como' ? (
    <h5>COM</h5>
) : fixture.teams.home.name === 'Genoa' ? (
    <h5>GEN</h5>
) : fixture.teams.home.name === 'Lecce' ? (
    <h5>LEC</h5>
) : fixture.teams.home.name === 'Venezia' ? (
    <h5>VEN</h5>
) : fixture.teams.home.name === 'Monza' ? (
    <h5>MON</h5>
) : fixture.teams.home.name === 'Cagliari' ? (
    <h5>CAG</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}



                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {fixture.teams.away.name === 'Torino' ? (
    <h5>TOR</h5>
) : fixture.teams.away.name === 'Napoli' ? (
    <h5>NAP</h5>
) : fixture.teams.away.name === 'Udinese' ? (
    <h5>UDI</h5>
) : fixture.teams.away.name === 'Juventus' ? (
    <h5>JUV</h5>
) : fixture.teams.away.name === 'Empoli' ? (
    <h5>EMP</h5>
) : fixture.teams.away.name === 'Inter' ? (
    <h5>INT</h5>
) : fixture.teams.away.name === 'AC Milan' ? (
    <h5>ACM</h5>
) : fixture.teams.away.name === 'Lazio' ? (
    <h5>LAZ</h5>
) : fixture.teams.away.name === 'AS Roma' ? (
    <h5>ROM</h5>
) : fixture.teams.away.name === 'Verona' ? (
    <h5>VER</h5>
) : fixture.teams.away.name === 'Fiorentina' ? (
    <h5>FIO</h5>
) : fixture.teams.away.name === 'Atalanta' ? (
    <h5>ATA</h5>
) : fixture.teams.away.name === 'Bologna' ? (
    <h5>BOL</h5>
) : fixture.teams.away.name === 'Parma' ? (
    <h5>PAR</h5>
) : fixture.teams.away.name === 'Como' ? (
    <h5>COM</h5>
) : fixture.teams.away.name === 'Genoa' ? (
    <h5>GEN</h5>
) : fixture.teams.away.name === 'Lecce' ? (
    <h5>LEC</h5>
) : fixture.teams.away.name === 'Venezia' ? (
    <h5>VEN</h5>
) : fixture.teams.away.name === 'Monza' ? (
    <h5>MON</h5>
) : fixture.teams.away.name === 'Cagliari' ? (
    <h5>CAG</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
            <Footer />
        </div>
    );
}

export default SerieAFixtures;
