


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import { useTranslation } from 'react-i18next';



function FixturesForJoinedBracket() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-06-11'), endDate: new Date('2024-08-20') },
        { number: 2, startDate: new Date('2024-08-20'), endDate: new Date('2024-08-26') },
        { number: 3, startDate: new Date('2024-08-26'), endDate: new Date('2024-09-02') },
        { number: 4, startDate: new Date('2024-09-02'), endDate: new Date('2024-09-16') },
        { number: 5, startDate: new Date('2024-09-16'), endDate: new Date('2024-09-23') },
        { number: 6, startDate: new Date('2024-09-23'), endDate: new Date('2024-09-31') },
        { number: 7, startDate: new Date('2024-09-31'), endDate: new Date('2024-10-07') },
        { number: 8, startDate: new Date('2024-10-07'), endDate: new Date('2024-10-22') },
        { number: 9, startDate: new Date('2024-10-22'), endDate: new Date('2024-10-28') },
        { number: 10, startDate: new Date('2024-10-28'), endDate: new Date('2024-11-03') },
        { number: 11, startDate: new Date('2024-11-03'), endDate: new Date('2024-11-10') }, 
        { number: 12, startDate: new Date('2024-11-10'), endDate: new Date('2024-11-24') },
        { number: 13, startDate: new Date('2024-11-24'), endDate: new Date('2024-12-01') },
        { number: 14, startDate: new Date('2024-12-01'), endDate: new Date('2024-12-05') },
        { number: 15, startDate: new Date('2024-12-05'), endDate: new Date('2024-12-08') },
        { number: 16, startDate: new Date('2024-12-08'), endDate: new Date('2024-12-15') },
        { number: 17, startDate: new Date('2024-12-15'), endDate: new Date('2024-12-22') },
        { number: 18, startDate: new Date('2024-12-22'), endDate: new Date('2024-12-27') },
        { number: 19, startDate: new Date('2024-12-27'), endDate: new Date('2024-12-30') },
        { number: 20, startDate: new Date('2025-01-01'), endDate: new Date('2025-01-06') },
        { number: 21, startDate: new Date('2025-01-06'), endDate: new Date('2025-01-16') },
        { number: 22, startDate: new Date('2025-01-16'), endDate: new Date('2025-01-19') },
        { number: 23, startDate: new Date('2025-01-19'), endDate: new Date('2025-01-26') },
        { number: 24, startDate: new Date('2025-01-26'), endDate: new Date('2025-02-02') },
        { number: 25, startDate: new Date('2025-02-02'), endDate: new Date('2025-02-16') },
        { number: 26, startDate: new Date('2025-02-16'), endDate: new Date('2025-02-23') },
        { number: 27, startDate: new Date('2025-02-23'), endDate: new Date('2025-02-27') },
        { number: 28, startDate: new Date('2025-02-27'), endDate: new Date('2025-03-09') },
        { number: 29, startDate: new Date('2025-03-09'), endDate: new Date('2025-03-16') },
        { number: 30, startDate: new Date('2025-03-16'), endDate: new Date('2025-04-03') },
        { number: 31, startDate: new Date('2025-04-03'), endDate: new Date('2025-04-06') },
        { number: 32, startDate: new Date('2025-04-06'), endDate: new Date('2025-04-13') },
        { number: 33, startDate: new Date('2025-04-13'), endDate: new Date('2025-04-20') },
        { number: 34, startDate: new Date('2025-04-20'), endDate: new Date('2025-04-27') },
        { number: 35, startDate: new Date('2025-04-27'), endDate: new Date('2025-05-04') },
        { number: 36, startDate: new Date('2025-05-04'), endDate: new Date('2025-05-11') },
        { number: 37, startDate: new Date('2025-05-11'), endDate: new Date('2025-05-19') },
        { number: 38, startDate: new Date('2025-05-19'), endDate: new Date('2025-07-20') },
    ];
    
    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className=''>
            <div className=''>
            <h2 className="text-dark text-center padtop mb-3">{t('epl_fixtures')}</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn-lg mb-5 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom text-light" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className=" mb-3" style={{marginBottom: '15px'}}>
                    <div className='mb-2'>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row">
                                        <div className="col text-center"> 
                                        {/* Display kit of home team */}
                                        {' '}
                                                        {fixture.teams.home.name === 'Arsenal' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                            </>
                                                        ) : fixture.teams.home.name === 'Aston Villa' ? (
                                                            <>
                                                                <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) : fixture.teams.home.name === 'Bournemouth' ? (
                                                            <>
                                                                <img src={Bournemouth} alt="Bournemouth" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) :
                                                            fixture.teams.home.name === 'Brentford' ? (
                                                                <>
                                                                    <img src={Brentford} alt="Brentford" style={{ width: '75px', height: 'auto' }} />
                                                                </>
                                                            ) :
                                                                fixture.teams.home.name === 'Brighton' ? (
                                                                    <>
                                                                        <img src={Brighton} alt="Brighton" style={{ width: '75px', height: 'auto' }} />

                                                                    </>
                                                                ) :
                                                                    fixture.teams.home.name === 'Burnley' ? (
                                                                        <>
                                                                            <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />

                                                                        </>
                                                                    ) :
                                                                        fixture.teams.home.name === 'Chelsea' ? (
                                                                            <>
                                                                                <img src={Chelsea} alt="Chelsea" style={{ width: '75px', height: 'auto' }} />

                                                                            </>
                                                                        ) :
                                                                            fixture.teams.home.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <img src={CrystalPalace} alt="Crystal Palace" style={{ width: '75px', height: 'auto' }} />

                                                                                </>
                                                                            ) :
                                                                                fixture.teams.home.name === 'Everton' ? (
                                                                                    <>
                                                                                        <img src={Everton} alt="Everton" style={{ width: '75px', height: 'auto' }} />
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.home.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <img src={Fulham} alt="Fulham" style={{ width: '75px', height: 'auto' }} />

                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.home.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />

                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.home.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />

                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.home.name === 'Manchester City' ? (
                                                                                                    <>

                                                                                                        <img src={ManCity} alt="Man City" style={{ width: '75px', height: 'auto' }} />

                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.home.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <img src={ManUnited} alt="Man United" style={{ width: '75px', height: 'auto' }} />

                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.home.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <img src={Newcastle} alt="Newcastle" style={{ width: '75px', height: 'auto' }} />

                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.home.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <img src={NottinghamForest} alt="Nottingham Forest" style={{ width: '75px', height: 'auto' }} />

                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.home.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.home.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <img src={Tottenham} alt="Tottenham" style={{ width: '75px', height: 'auto' }} />

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.home.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <img src={WestHam} alt="West Ham" style={{ width: '75px', height: 'auto' }} />

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.home.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Wolves} alt="Wolves" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Ipswich} alt="Ipswich" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Leicester' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Leicester} alt="Leicester" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.home.name === 'Southampton' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Southampton} alt="Southampton" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}  
                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {' '}
                                                        {fixture.teams.away.name === 'Arsenal' ? (
                                                            <>
                                                              <div className='Arsenal' ><img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                                            </>
                                                        ) : fixture.teams.away.name === 'Aston Villa' ? (
                                                            <>
                                                                <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) : fixture.teams.away.name === 'Bournemouth' ? (
                                                            <>
                                                                <img src={Bournemouth} alt="Bournemouth" style={{ width: '75px', height: 'auto' }} />
                                                            </>
                                                        ) :
                                                            fixture.teams.away.name === 'Brentford' ? (
                                                                <>
                                                                    <img src={Brentford} alt="Brentford" style={{ width: '75px', height: 'auto' }} />
                                                                </>
                                                            ) :
                                                                fixture.teams.away.name === 'Brighton' ? (
                                                                    <>
                                                                        <img src={Brighton} alt="Brighton" style={{ width: '75px', height: 'auto' }} />
                                                                    </>
                                                                ) :
                                                                    fixture.teams.away.name === 'Burnley' ? (
                                                                        <>
                                                                            <img src={Burnley} alt="Burnley" style={{ width: '75px', height: 'auto' }} />
                                                                        </>
                                                                    ) :
                                                                        fixture.teams.away.name === 'Chelsea' ? (
                                                                            <>
                                                                                <img src={Chelsea} alt="Chelsea" style={{ width: '75px', height: 'auto' }} />
                                                                            </>
                                                                        ) :
                                                                            fixture.teams.away.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <img src={CrystalPalace} alt="Crystal Palace" style={{ width: '75px', height: 'auto' }} />
                                                                                </>
                                                                            ) :
                                                                                fixture.teams.away.name === 'Everton' ? (
                                                                                    <>
                                                                                        <img src={Everton} alt="Everton" style={{ width: '75px', height: 'auto' }} />
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.away.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <img src={Fulham} alt="Fulham" style={{ width: '75px', height: 'auto' }} />
                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.away.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />
                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.away.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <img src={Luton} alt="Luton" style={{ width: '75px', height: 'auto' }} />
                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.away.name === 'Manchester City' ? (
                                                                                                    <>
                                                                                                        <img src={ManCity} alt="Man City" style={{ width: '75px', height: 'auto' }} />
                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.away.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <img src={ManUnited} alt="Man United" style={{ width: '75px', height: 'auto' }} />
                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.away.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <img src={Newcastle} alt="Newcastle" style={{ width: '75px', height: 'auto' }} />
                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.away.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <img src={NottinghamForest} alt="Nottingham Forest" style={{ width: '75px', height: 'auto' }} />
                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.away.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <img src={SheffieldUnited} alt="Sheffield Utd" style={{ width: '75px', height: 'auto' }} />
                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.away.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <img src={Tottenham} alt="Tottenham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.away.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <img src={WestHam} alt="West Ham" style={{ width: '75px', height: 'auto' }} />
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.away.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Wolves} alt="Wolves" style={{ width: '75px', height: 'auto' }} />
                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.away.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Ipswich} alt="Ipswich" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Leicester' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Leicester} alt="Leicester" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.away.name === 'Southampton' ? (
                                                                                                                                <>
                                                                                                                                    <img src={Southampton} alt="Southampton" style={{ width: '75px', height: 'auto' }} />

                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )} 
                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                    {' '}
                                                        {fixture.teams.home.name === 'Arsenal' ? (
                                                            <>
                                                                <h5>ARS </h5>
                                                            </>
                                                        ) : fixture.teams.home.name === 'Aston Villa' ? (
                                                            <>
                                                                <h5>AVL </h5>
                                                            </>
                                                        ) : fixture.teams.home.name === 'Bournemouth' ? (
                                                            <>
                                                                <h5>BOU </h5>
                                                            </>
                                                        ) :
                                                            fixture.teams.home.name === 'Brentford' ? (
                                                                <>
                                                                    <h5>BRE </h5>
                                                                </>
                                                            ) :
                                                                fixture.teams.home.name === 'Brighton' ? (
                                                                    <>
                                                                        <h5>BRI </h5>

                                                                    </>
                                                                ) :
                                                                    fixture.teams.home.name === 'Burnley' ? (
                                                                        <>
                                                                            <h5>BUR </h5>

                                                                        </>
                                                                    ) :
                                                                        fixture.teams.home.name === 'Chelsea' ? (
                                                                            <>
                                                                                <h5>CHE </h5>

                                                                            </>
                                                                        ) :
                                                                            fixture.teams.home.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <h5>CRY</h5>

                                                                                </>
                                                                            ) :
                                                                                fixture.teams.home.name === 'Everton' ? (
                                                                                    <>
                                                                                        <h5>EVE </h5>
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.home.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <h5>FUL </h5>

                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.home.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <h5>LIV </h5>

                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.home.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <h5>LUT </h5>

                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.home.name === 'Manchester City' ? (
                                                                                                    <>

                                                                                                        <h5>MCI </h5>

                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.home.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <h5>MUN </h5>

                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.home.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <h5>NEW </h5>

                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.home.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <h5>NFO </h5>

                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.home.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <h5>SHU </h5>

                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.home.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <h5>TOT </h5>

                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.home.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <h5>WHU </h5>

                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.home.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <h5>WOL </h5>

                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.home.name === 'Leicester' ? (
                                                                                                                                <>
                                                                                                                                    <h5>LEI </h5>

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.home.name === 'Southampton' ? (
                                                                                                                                <>
                                                                                                                                    <h5>SOU </h5>

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.home.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <h5>IPS </h5>

                                                                                                                                </>
                                                                                                                            ) :(
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.home.name}
                                                                                                                                </span>
                                                                                                                            )}    
                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {' '}
                                                        {fixture.teams.away.name === 'Arsenal' ? (
                                                            <>
                                                                <h5 >ARS </h5>
                                                            </>
                                                        ) : fixture.teams.away.name === 'Aston Villa' ? (
                                                            <>
                                                                <h5 >AVL</h5>
                                                            </>
                                                        ) : fixture.teams.away.name === 'Bournemouth' ? (
                                                            <>
                                                                <h5 >BOU</h5>
                                                            </>
                                                        ) :
                                                            fixture.teams.away.name === 'Brentford' ? (
                                                                <>
                                                                    <h5 >BRE</h5>
                                                                </>
                                                            ) :
                                                                fixture.teams.away.name === 'Brighton' ? (
                                                                    <>
                                                                        <h5 >BRI</h5>
                                                                    </>
                                                                ) :
                                                                    fixture.teams.away.name === 'Burnley' ? (
                                                                        <>
                                                                            <h5 >BUR</h5>
                                                                        </>
                                                                    ) :
                                                                        fixture.teams.away.name === 'Chelsea' ? (
                                                                            <>
                                                                                <h5 h5>CHE</h5>
                                                                            </>
                                                                        ) :
                                                                            fixture.teams.away.name === 'Crystal Palace' ? (
                                                                                <>
                                                                                    <h5 >CRY</h5>
                                                                                </>
                                                                            ) :
                                                                                fixture.teams.away.name === 'Everton' ? (
                                                                                    <>
                                                                                        <h5 >EVE</h5>
                                                                                    </>
                                                                                ) :
                                                                                    fixture.teams.away.name === 'Fulham' ? (
                                                                                        <>
                                                                                            <h5 >FUL</h5>
                                                                                        </>
                                                                                    ) :
                                                                                        fixture.teams.away.name === 'Liverpool' ? (
                                                                                            <>
                                                                                                <h5 >LIV</h5>
                                                                                            </>
                                                                                        ) :
                                                                                            fixture.teams.away.name === 'Luton' ? (
                                                                                                <>
                                                                                                    <h5 >LUT</h5>
                                                                                                </>
                                                                                            ) :
                                                                                                fixture.teams.away.name === 'Manchester City' ? (
                                                                                                    <>
                                                                                                        <h5 >MCI</h5>
                                                                                                    </>
                                                                                                ) :
                                                                                                    fixture.teams.away.name === 'Manchester United' ? (
                                                                                                        <>
                                                                                                            <h5 >MUN</h5>
                                                                                                        </>
                                                                                                    ) :
                                                                                                        fixture.teams.away.name === 'Newcastle' ? (
                                                                                                            <>
                                                                                                                <h5 >NEW</h5>
                                                                                                            </>
                                                                                                        ) :
                                                                                                            fixture.teams.away.name === 'Nottingham Forest' ? (
                                                                                                                <>
                                                                                                                    <h5 >NFO</h5>
                                                                                                                </>
                                                                                                            ) :
                                                                                                                fixture.teams.away.name === 'Sheffield Utd' ? (
                                                                                                                    <>
                                                                                                                        <h5 >SHU</h5>
                                                                                                                    </>
                                                                                                                ) :
                                                                                                                    fixture.teams.away.name === 'Tottenham' ? (
                                                                                                                        <>
                                                                                                                            <h5 >TOT</h5>
                                                                                                                        </>
                                                                                                                    ) :
                                                                                                                        fixture.teams.away.name === 'West Ham' ? (
                                                                                                                            <>
                                                                                                                                <h5 >WHU</h5>
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                            fixture.teams.away.name === 'Wolves' ? (
                                                                                                                                <>
                                                                                                                                    <h5 >WOL</h5>
                                                                                                                                </>
                                                                                                                            ) :
                                                                                                                            fixture.teams.away.name === 'Leicester' ? (
                                                                                                                                <>
                                                                                                                                    <h5>LEI </h5>

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.away.name === 'Southampton' ? (
                                                                                                                                <>
                                                                                                                                    <h5>SOU </h5>

                                                                                                                                </>
                                                                                                                            ) : 
                                                                                                                            fixture.teams.away.name === 'Ipswich' ? (
                                                                                                                                <>
                                                                                                                                    <h5>IPS </h5>

                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <span style={{ fontStyle: 'normal' }}>
                                                                                                                                    {fixture.teams.away.name}
                                                                                                                                </span>
                                                                                                                            )} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
        </div>
    );
}

export default FixturesForJoinedBracket;
