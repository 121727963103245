import React, { useState, useEffect } from 'react';
import { ref, get, update, getDatabase, onValue, push } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

function JoinBracket() {
    const [brackets, setBrackets] = useState([]);
    const [userUid, setUserUid] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [tournamentFilter, setTournamentFilter] = useState('All'); // Initial filter state
    const location = useLocation();
    const { t } = useTranslation();

    const auth = getAuth();

    useEffect(() => {
        const fetchBrackets = async () => {
            try {
                const database = getDatabase();
                const bracketsRef = ref(database, 'CreateBracket');
                onValue(bracketsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const bracketsArray = Object.entries(data);
                        setBrackets(bracketsArray);
                    }
                });
            } catch (error) {
                console.error('Error fetching brackets:', error);
            }
        };

        fetchBrackets();

        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserUid(user.uid); // Set user UID when authenticated
            } else {
                setUserUid(''); // Reset user UID if not authenticated
            }
        });

        // Clean up subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleJoinBracket = async (bracketId, bracket) => {
        try {
            const database = getDatabase();
            const bracketRef = ref(database, `CreateBracket/${bracketId}`);

            // Retrieve current bracket data
            const bracketSnapshot = await get(bracketRef);
            const bracketData = bracketSnapshot.val();

            // Check if the user has already joined
            if (bracketData.participants && bracketData.participants[userUid]) {
                setError('User has already joined this bracket.');
                console.log('User has already joined this bracket.');
                return;
            }

            // Check if the bracket is full
            if (bracketData.participantsCount >= bracketData.maxAmountOfPlayers) {
                setError('Huddle is currently full!');
                console.error('Max amount of players in Huddle');
                return;
            }

            // Get the user's displayName and email
            const user = auth.currentUser;
            const userDisplayName = user.displayName;
            const userEmail = user.email;

            // Update participants count and add user details to the list
            const updatedParticipantsCount = bracketData.participantsCount + 1;
            const updatedParticipants = {
                ...bracketData.participants,
                [userUid]: {
                    displayName: userDisplayName,
                    email: userEmail
                }
            };

            // Update bracket data with new participants count and list
            await Promise.all([
                update(bracketRef, {
                    participantsCount: updatedParticipantsCount,
                    participants: updatedParticipants
                }),
                push(ref(database, `BracketParticipants/${bracketId}`), { // Push user details to BracketParticipants node
                    displayName: userDisplayName,
                    email: userEmail
                })
            ]);

            // Navigate to the participants page
            navigate(`/bracket/${bracketId}/${encodeURIComponent(bracket.nameOfBracket)}/participants`);
        } catch (error) {
            console.error('Error joining Huddle:', error);
        }
    };

    const handleSearch = async () => {
        try {
            const foundBracket = brackets.find(([bracketId, bracket]) => bracket.nameOfBracket === searchQuery);
            if (foundBracket) {
                const [bracketId, bracket] = foundBracket;

                // Check if the bracket is full (optional, if needed to prevent full brackets from being accessed)
                if (bracket.participantsCount >= bracket.maxAmountOfPlayers) {
                    setError('Huddle is currently full!');
                    console.error('Max amount of players in Huddle');
                    return;
                }

                // Check if the user has already joined this bracket
                const participantKey = Object.keys(bracket.participants).find(key => key === userUid);
                if (participantKey) {
                    navigate(`/bracket/${bracketId}/${encodeURIComponent(bracket.nameOfBracket)}/participants`);
                } else {
                    if (bracket.isPublic || (!bracket.isPublic && bracket.nameOfBracket === searchQuery)) {
                        // Redirect to the bracket without joining it
                        navigate(`/bracket/${bracketId}/${encodeURIComponent(bracket.nameOfBracket)}/participants`);
                    }
                }
            } else {
                console.error('Huddle not found');
                setError('Huddle not found or is currently full');
                return;
            }
        } catch (error) {
            setError('Huddle not found');
            return;
        }
    };

    const handleTournamentChange = (event) => {
        setTournamentFilter(event.target.value);
    };

    useEffect(() => {
        if (location.state?.tournament) {
            setTournamentFilter(location.state.tournament);
        }
    }, [location.state]);

    const handleViewBracket = (bracket, bracketId) => {
        navigate(`/bracket/${bracketId}/${encodeURIComponent(bracket.nameOfBracket)}/participants`);
    };
 
    const filteredBrackets = tournamentFilter === 'All' ? brackets : brackets.filter(([_, bracket]) => bracket.tournament === tournamentFilter);

    return (
        <div>
            <Navbar />
            <div className='App-headerV3'>
                <div className="container padtop">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9 col-xl-8">
                            <h2 className="mb-4 display-5 text-center padtop" style={{ color: 'black' }}>{t('join_huddle')}</h2>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                            <div className='d-flex col-11 mx-auto'>
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginRight: '5px' }}
                                    placeholder="Enter Huddle name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="btn btn-purple" type="button" onClick={handleSearch}>{t('search')}</button>
                            </div>

                            <br />
                            <div>
                                {error && (
                                    <div className='alert alert-danger text-center' role='alert'>{error}</div>
                                )}
                            </div>
                            <div className='text-end'>
                                {/* Additional elements */}
                            </div>

                            <div className="card widget-card border-light shadow-sm mb-3">
                                <div className="card-body p-4">
                                    <h5 className="card-title widget-card-title mb-4">{t('join_huddle1')}</h5>
                                    <select
                                        id="tournamentSelect"
                                        className="form-control d-inline-block w-auto ms-2  text-light text-center mb-3 "
                                        value={tournamentFilter}
                                        style={{ backgroundColor: 'purple', color: 'white' }}

                                        onChange={handleTournamentChange}
                                    >
                                        <option value="All" style={{ backgroundColor: 'white', color: 'black' }}>{t('filter_huddles')}</option>
                                        {/* <option value="EURO 2024" style={{ backgroundColor: 'white', color: 'black' }}>{t('euro_2024_huddles')}</option> */}
                                        <option value="English Premier League" style={{ backgroundColor: 'white', color: 'black' }}>EPL 24/25</option>
                                        <option value="Scottish Premiership" style={{ backgroundColor: 'white', color: 'black' }}>SPFL 24/25</option>
                                        <option value="Championship" style={{ backgroundColor: 'white', color: 'black' }}>Championship 24/25</option>
                                        <option value="La Liga" style={{ backgroundColor: 'white', color: 'black' }}>La Liga 24/25</option>
                                        <option value="UCL" style={{ backgroundColor: 'white', color: 'black' }}>UCL 24/25</option>
                                        <option value="Bundesliga" style={{ backgroundColor: 'white', color: 'black' }}>Bundesliga 24/25</option>
                                        <option value="Serie A" style={{ backgroundColor: 'white', color: 'black' }}>Serie A 24/25</option>
                                        <option value="SASoccer" style={{ backgroundColor: 'white', color: 'black' }}>Premier Soccer League 24/25</option>




                                    </select>
                                    <div className="table-responsive mb-3">
                                        <table className="table table-borderless bsb-table-xl text-nowrap align-middle m-0">
                                            <thead>
                                                <tr>
                                                    <th>{t('status')}</th>
                                                    <th>{t('name')}</th>
                                                    <th>{t('captain')}</th>
                                                    <th>{t('competition')}</th>
                                                    <th>{t('players')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredBrackets
                                                    .filter(([_, bracket]) => bracket.isPublic && (tournamentFilter === 'All' || bracket.tournament === tournamentFilter))
                                                    .map(([bracketId, bracket], index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className='mb-1'>
                                                                    {bracket.participants && bracket.participants[userUid] ? (
                                                                        <Link to={`/bracket/${bracketId}/${bracket.nameOfBracket}/participants`}>
                                                                            <span className="badge bg-success bsb-w-85">
                                                                                Joined
                                                                            </span>
                                                                        </Link>
                                                                    ) : bracket.participantsCount >= bracket.maxAmountOfPlayers ? (
                                                                        <span className="badge bg-danger bsb-w-85">
                                                                            Full
                                                                        </span>
                                                                    ) : (
                                                                        <span className="badge bg-success bsb-w-85" onClick={() => handleJoinBracket(bracketId, bracket)}>Join</span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <img src={bracket.image} alt={bracket.nameOfBracket} style={{ width: '25px', height: '25px', objectFit: 'cover', marginRight: '10px' }} />
                                                                    <div onClick={() => handleViewBracket(bracket)} style={{ cursor: 'pointer' }}>
                                                                        <h6 className="m-0">{bracket.nameOfBracket}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <h6 className="mb-1">{bracket.captainsName}</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className="mb-1">{bracket.tournament}</h6>
                                                            </td>
                                                            <td>
                                                                <h6 className='mb-1'>{bracket.participantsCount} / {bracket.maxAmountOfPlayers}</h6>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default JoinBracket;
