import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function BundesligaStandingsPicks() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};

    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
      standingsMap[fixture.teams.away.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = "";
      let awayResult = "";

      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }

      // Update standings
      standingsMap[homeTeam].points += homePoints;
      standingsMap[awayTeam].points += awayPoints;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
    });

    // Convert standings map to array
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <div className=''>
      <div className=''>
  



<div className='container'>
    <h5>Standings</h5>
    <hr className='w-25' />
</div>
        <div className='container'>
          {loading ? (
            <h2 className='text-center text-dark'><Spinner /></h2>
          ) : (
            <table className="table table table-striped" style={{ backgroundColor: '' ,  }}>
              <thead className='' style={{ backgroundColor: '', color: 'grey' , fontFamily: 'normal' }}>
                <tr>
                <th
  scope="col"
  style={{ backgroundColor: '', color: '', fontFamily: 'Arial, sans-serif', fontStyle: 'normal' }}
>
  #
</th>

{/* Arial
text-normal: Normal font weight (font-weight: normal;).
text-bold: Bold font weight (font-weight: bold;).
text-light: Light font weight (font-weight: 300;).
text-semibold: Semi-bold font weight (font-weight: 600;).
text-extrabold: Extra-bold font weight (font-weight: 800;). */}
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'bold' }}>Team</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>P</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>W</th>
                  <th scope="col" style={{ backgroundColor: '', color: ''  , fontFamily: 'normal'}}>D</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>L</th>
                  <th scope="col" style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Pts</th>
                </tr>
              </thead>
              <tbody >
                {standings.map((team, index) => (
                  <tr key={index}>
                    <th scope="row" className={index < 4 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 4 ? 'text-warning' :  index == 5 ? 'text-warning' :  index == 6 ? 'text-success' : ''} style={{ backgroundColor: '', color: '' ,  }}>{index + 1}</th>
                    <td className='' style={{ backgroundColor: '', color: '' ,  }}>
                      {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'black' , fontFamily: '   normal                    '  }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Arsenal' ? (
                        <Link to="/arsenal" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Arsenal</Link>
                      ) : team.team === 'Manchester City' ? (
                        <Link to="/mancity" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Man City</Link>
                      ) : team.team === 'Liverpool' ? (
                        <Link to="/liverpool" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Liverpool</Link>
                      ) : team.team === 'Aston Villa' ? (
                        <Link to="/astonvilla" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Aston Villa</Link>
                      ) : team.team === 'Tottenham' ? (
                        <Link to="/tottenham" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Tottenham</Link>
                      ) : team.team === 'Chelsea' ? (
                        <Link to="/chelsea" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Chelsea</Link>
                      ) : team.team === 'Newcastle' ? (
                        <Link to="/newcastle" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Newcastle</Link>
                      ) : team.team === 'West Ham' ? (
                        <Link to="/westham" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>West Ham</Link>
                      ) : team.team === 'Crystal Palace' ? (
                        <Link to="/crystalpalace" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Crystal Palace</Link>
                      ) : team.team === 'Bournemouth' ? (
                        <Link to="/bournemouth" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Bournemouth</Link>
                      ) : team.team === 'Brighton' ? (
                        <Link to="/brighton" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Brighton</Link>
                      ) : team.team === 'Everton' ? (
                        <Link to="/everton" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Everton</Link>
                      ) : team.team === 'Fulham' ? (
                        <Link to="/fulham" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Fulham</Link>
                      ) : team.team === 'Wolves' ? (
                        <Link to="/wolves" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Wolves</Link>
                      ) : team.team === 'Brentford' ? (
                        <Link to="/brentford" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Brentford</Link>
                      ) : team.team === 'Nottingham Forest' ? (
                        <Link to="/nottinghamforest" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Nott. Forest</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Luton</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/burnley" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Burnley</Link>
                      ) : team.team === 'Sheffield Utd' ? (
                        <Link to="/sheffieldutd" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Sheffield</Link>
                      ) : team.team === 'Leicester' ? (
                        <Link to="/Leicester" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Leicester</Link>
                      ) : team.team === 'Ipswich' ? (
                        <Link to="/Ipswich" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Ipswich</Link>
                      ) : team.team === 'Southampton' ? (
                        <Link to="/Southampton" style={{ textDecoration: 'none', color: 'black' , fontFamily: 'normal' }}>Southampton</Link>
                      ) : (
                        team.team
                      )}
                    </td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.matchesPlayed}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.wins}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.draws}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.losses}</td>
                    <td style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-dark text-center' style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }} >UEFA Champions League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-dark text-center' style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Europa League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-success'>| </div>
            <div className='text-dark text-center' style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Europa Conference League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-dark text-center' style={{ backgroundColor: '', color: '' , fontFamily: 'normal' }}>Relegation</div>
          </div>
        </div>
       
      </div>

    </div>
  );
}

export default BundesligaStandingsPicks;
