


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Sundowns from '../Team-Kits-Images/Sundowns.png'
import Pirates from '../Team-Kits-Images/Pirates.png'
import Arrows from '../Team-Kits-Images/Arrows.png'
import Polokwane from '../Team-Kits-Images/Polokwane.png'
import Richards from '../Team-Kits-Images/Richards.png'
import Kaizer from '../Team-Kits-Images/Kaizer.png'
import Sekh from '../Team-Kits-Images/Sekh.png'
import Chippa from '../Team-Kits-Images/Chippa.png'
import Royal from '../Team-Kits-Images/Royal.png'
import Cape from '../Team-Kits-Images/Cape.png'
import Supersport from '../Team-Kits-Images/Supersport.png'
import Amazulu from '../Team-Kits-Images/Amazulu.png'
import Galaxy from '../Team-Kits-Images/Galaxy.png'
import Stell from '../Team-Kits-Images/Stell.png'
import Magesi from '../Team-Kits-Images/Magesi.png'
import Marumo from '../Team-Kits-Images/Margumo.png'

import { useTranslation } from 'react-i18next';



function SASoccerFixtures() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-09-01'), endDate: new Date('2024-09-30') },  // Matchweek 1 (September 2024)
        { number: 2, startDate: new Date('2024-10-01'), endDate: new Date('2024-10-31') },  // Matchweek 2 (October 2024)
        { number: 3, startDate: new Date('2024-11-01'), endDate: new Date('2024-11-30') },  // Matchweek 3 (November 2024)
        { number: 4, startDate: new Date('2024-12-01'), endDate: new Date('2024-12-31') },  // Matchweek 4 (December 2024)
        { number: 5, startDate: new Date('2025-01-01'), endDate: new Date('2025-01-31') },  // Matchweek 5 (January 2025)
        { number: 6, startDate: new Date('2025-02-01'), endDate: new Date('2025-02-28') },  // Matchweek 6 (February 2025)
        { number: 7, startDate: new Date('2025-03-01'), endDate: new Date('2025-03-31') },  // Matchweek 7 (March 2025)
        { number: 8, startDate: new Date('2025-04-01'), endDate: new Date('2025-04-30') },  // Matchweek 8 (April 2025)
        { number: 9, startDate: new Date('2025-05-01'), endDate: new Date('2025-05-31') },  // Matchweek 9 (May 2025)
    ];
    
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/2024?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='App-headerV3'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="text-dark text-center padtop mb-3">Premier Soccer League Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Mamelodi Sundowns' ? (
    <div className='Sundowns'>
        <img src={Sundowns} alt="Mamelodi Sundowns" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.home.name === 'Orlando Pirates' ? (
    <img src={Pirates} alt="Orlando Pirates" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Golden Arrows' ? (
    <img src={Arrows} alt="Golden Arrows" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Polokwane City' ? (
    <img src={Polokwane} alt="Polokwane City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Richards Bay' ? (
    <img src={Richards} alt="Richards Bay" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Kaizer Chiefs' ? (
    <img src={Kaizer} alt="Kaizer Chiefs" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Sekhukhune United' ? (
    <img src={Sekh} alt="Sekhukhune United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Chippa United' ? (
    <img src={Chippa} alt="Chippa United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Royal AM' ? (
    <img src={Royal} alt="Royal AM" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Cape Town City' ? (
    <img src={Cape} alt="Cape Town City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Supersport United' ? (
    <img src={Supersport} alt="Supersport United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Amazulu' ? (
    <img src={Amazulu} alt="Amazulu" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'TS Galaxy' ? (
    <img src={Galaxy} alt="TS Galaxy" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Stellenbosch' ? (
    <img src={Stell} alt="Stellenbosch" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Magesi' ? (
    <img src={Magesi} alt="Magesi" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Marumo Gallants' ? (
    <img src={Marumo} alt="Marumo Gallants" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {fixture.teams.away.name === 'Mamelodi Sundowns' ? (
    <div className='Sundowns'>
        <img src={Sundowns} alt="Mamelodi Sundowns" style={{ width: '75px', height: 'auto' }} />
    </div>
) : fixture.teams.away.name === 'Orlando Pirates' ? (
    <img src={Pirates} alt="Orlando Pirates" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Golden Arrows' ? (
    <img src={Arrows} alt="Golden Arrows" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Polokwane City' ? (
    <img src={Polokwane} alt="Polokwane City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Richards Bay' ? (
    <img src={Richards} alt="Richards Bay" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Kaizer Chiefs' ? (
    <img src={Kaizer} alt="Kaizer Chiefs" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Sekhukhune United' ? (
    <img src={Sekh} alt="Sekhukhune United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Chippa United' ? (
    <img src={Chippa} alt="Chippa United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Royal AM' ? (
    <img src={Royal} alt="Royal AM" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Cape Town City' ? (
    <img src={Cape} alt="Cape Town City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Supersport United' ? (
    <img src={Supersport} alt="Supersport United" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Amazulu' ? (
    <img src={Amazulu} alt="Amazulu" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'TS Galaxy' ? (
    <img src={Galaxy} alt="TS Galaxy" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Stellenbosch' ? (
    <img src={Stell} alt="Stellenbosch" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Magesi' ? (
    <img src={Magesi} alt="Magesi" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Marumo Gallants' ? (
    <img src={Marumo} alt="Marumo Gallants" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}


                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                                                                                {fixture.teams.home.name === 'Mamelodi Sundowns' ? (
    <h5>MSFC</h5>
) : fixture.teams.home.name === 'Orlando Pirates' ? (
    <h5>OPFC</h5>
) : fixture.teams.home.name === 'Golden Arrows' ? (
    <h5>GAFC</h5>
) : fixture.teams.home.name === 'Polokwane City' ? (
    <h5>PFC</h5>
) : fixture.teams.home.name === 'Richards Bay' ? (
    <h5>RBFC</h5>
) : fixture.teams.home.name === 'Kaizer Chiefs' ? (
    <h5>KCFC</h5>
) : fixture.teams.home.name === 'Sekhukhune United' ? (
    <h5>SUFC</h5>
) : fixture.teams.home.name === 'Chippa United' ? (
    <h5>CUFC</h5>
) : fixture.teams.home.name === 'Royal AM' ? (
    <h5>RAM</h5>
) : fixture.teams.home.name === 'Cape Town City' ? (
    <h5>CTC</h5>
) : fixture.teams.home.name === 'Supersport United' ? (
    <h5>SSU</h5>
) : fixture.teams.home.name === 'Amazulu' ? (
    <h5>AFC</h5>
) : fixture.teams.home.name === 'TS Galaxy' ? (
    <h5>TSG</h5>
) : fixture.teams.home.name === 'Stellenbosch' ? (
    <h5>SFC</h5>
) : fixture.teams.home.name === 'Magesi' ? (
    <h5>MFC</h5>
) : fixture.teams.home.name === 'Marumo Gallants' ? (
    <h5>MGFC</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}



                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {fixture.teams.away.name === 'Mamelodi Sundowns' ? (
    <h5>MSFC</h5>
) : fixture.teams.away.name === 'Orlando Pirates' ? (
    <h5>OPFC</h5>
) : fixture.teams.away.name === 'Golden Arrows' ? (
    <h5>GAFC</h5>
) : fixture.teams.away.name === 'Polokwane City' ? (
    <h5>PFC</h5>
) : fixture.teams.away.name === 'Richards Bay' ? (
    <h5>RBFC</h5>
) : fixture.teams.away.name === 'Kaizer Chiefs' ? (
    <h5>KCFC</h5>
) : fixture.teams.away.name === 'Sekhukhune United' ? (
    <h5>SUFC</h5>
) : fixture.teams.away.name === 'Chippa United' ? (
    <h5>CUFC</h5>
) : fixture.teams.away.name === 'Royal AM' ? (
    <h5>RAM</h5>
) : fixture.teams.away.name === 'Cape Town City' ? (
    <h5>CTC</h5>
) : fixture.teams.away.name === 'Supersport United' ? (
    <h5>SSU</h5>
) : fixture.teams.away.name === 'Amazulu' ? (
    <h5>AFC</h5>
) : fixture.teams.away.name === 'TS Galaxy' ? (
    <h5>TSG</h5>
) : fixture.teams.away.name === 'Stellenbosch' ? (
    <h5>SFC</h5>
) : fixture.teams.away.name === 'Magesi' ? (
    <h5>MFC</h5>
) : fixture.teams.away.name === 'Marumo Gallants' ? (
    <h5>MGFC</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
            <Footer />
        </div>
    );
}

export default SASoccerFixtures;
