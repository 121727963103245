import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function UclStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const [teamFixtures, setTeamFixtures] = useState({ finished: [], notStarted: [] }); // Initialize with empty arrays
  const navigate = useNavigate();

  const uclTeams = [
    'Bayern München', 'Aston Villa', 'Liverpool', 'Juventus', 'Atletico Madrid', 'Real Madrid', 'Sporting CP',
    'Arsenal', 'Atalanta', 'Barcelona', 'Bayer Leverkusen', 'Benfica', 'Bologna', 'Borussia Dortmund', 
    'Stade Brestois 29', 'Celtic', 'Club Brugge KV', 'Feyenoord', 'Girona', 'Inter', 'Manchester City', 
    'Monaco', 'Paris Saint Germain', 'RB Leipzig', 'FK Crvena Zvezda', 'Red Bull Salzburg', 'Shakhtar Donetsk',
    'Slovan Bratislava', 'Sparta Praha', 'Sturm Graz', 'PSV Eindhoven', 'VfB Stuttgart', 'AC Milan', 'Lille',
    'BSC Young Boys', 'Dinamo Zagreb'
  ];

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);

    // Fetch and display fixtures for Aston Villa
    const astonVillaFixtures = data.filter(fixture => 
      (fixture.teams.home.name === 'Aston Villa' || fixture.teams.away.name === 'Aston Villa')
    );

    // Separate finished and not started fixtures
    const finishedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'FT');
    const notStartedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'NS');

    // Filter fixtures for those played after September 16, 2024
    const cutoffDate = new Date('2024-09-16');
    const relevantFinishedFixtures = finishedFixtures.filter(fixture => new Date(fixture.fixture.date) > cutoffDate);

    setTeamFixtures({
      finished: relevantFinishedFixtures,
      notStarted: notStartedFixtures
    });

    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
    const cutoffDate = new Date('2024-09-16'); // Cutoff date

    // Initialize standingsMap with UCL teams and 0 points
    uclTeams.forEach((team) => {
      standingsMap[team] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each concluded fixture after the cutoff date
    fixtures.forEach((fixture) => {
      const fixtureDate = new Date(fixture.fixture.date);
      if (fixtureDate <= cutoffDate || fixture.fixture.status.short !== 'FT') {
        return; // Skip fixtures before or on the cutoff date and non-concluded fixtures
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Skip teams that are not in the UCL
      if (!uclTeams.includes(homeTeam) && !uclTeams.includes(awayTeam)) {
        return;
      }

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;

      if (homeGoals > awayGoals) {
        homePoints = 3;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].wins += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].losses += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].draws += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].draws += 1;
      }

      // Update standings for UCL teams only
      if (uclTeams.includes(homeTeam)) {
        standingsMap[homeTeam].points += homePoints;
        standingsMap[homeTeam].matchesPlayed += 1;
      }
      if (uclTeams.includes(awayTeam)) {
        standingsMap[awayTeam].points += awayPoints;
        standingsMap[awayTeam].matchesPlayed += 1;
      }
    });

    // Convert standings map to array and sort by points
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <div>
      <div className='App-headerV3'>
        <Navbar />
        <h2 className='text-center text-dark padtop mb-1'>UCL Standings</h2>
        <hr className="w-40 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        <div className='container text-center mb-2'>
          {/* <i className='text-center'>*Click on team name to view stats</i> */}
        </div>
        <div className='d-flex justify-content-center'>
          <div className='col-6 col-md-3 text-center'>
            <select 
              className='form-select mb-3 text-center' 
              style={{ backgroundColor: 'purple', color: 'white' }}
              value={season} 
              onChange={(e) => setSeason(e.target.value)}
            >
              <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>UCL 24/25</option>
            </select>
          </div>
        </div>

        <div className='container'>
          {loading ? (
            <h2 className='text-center text-dark'><Spinner /></h2>
          ) : (
            <>
              {/* UCL Standings Table */}
              <table className="table table-striped">
                <thead className='' style={{ backgroundColor: '', color: 'black' }}>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Team</th>
                    <th scope="col">MP</th>
                    <th scope="col">W</th>
                    <th scope="col">D</th>
                    <th scope="col">L</th>
                    <th scope="col">Pts</th>
                  </tr>
                </thead>
                <tbody>
                  {standings.map((team, index) => (
                    <tr key={index}>
                      <th scope="row" className={
            index < 8 ? 'text-primary' : // Positions 1-8: Blue
            index >= 8 && index < 24 ? 'text-warning' : // Positions 9-24: Yellow
            'text-danger' // Positions 25 and below: Red
          }>
                        {index + 1}
                      </th>
                      <td>
                        {team.team === 'Manchester United' ? (
                          <Link to="/manunited" style={{ textDecoration: 'none', color: 'black' }}>
                            Man Utd
                          </Link>
                        ) : team.team === 'Arsenal' ? (
                          <Link to="/arsenal" style={{ textDecoration: 'none', color: 'black' }}>Arsenal</Link>
                        ) : team.team === 'Manchester City' ? (
                          <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Mac City</Link>
                        ) : (
                          team.team
                        )}
                      </td>
                      <td>{team.matchesPlayed}</td>
                      <td>{team.wins}</td>
                      <td>{team.draws}</td>
                      <td>{team.losses}</td>
                      <td>{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            
              {/* Position Information */}
              <div className='d-flex justify-content-center'>
                <div className='text-center text-primary'>| </div>
                <div className='text-dark'>Position 1 - 8 : 8th Finals</div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='text-center text-warning'>| </div>
                <div className='text-dark'>Position 9 - 24 : Play-off</div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='text-center text-danger'>| </div>
                <div className='text-dark'>Position 25 - 36 : Out</div>
              </div>
            </>
          )}
          <div className='container'>
            <div className="text- mt-4" style={{ paddingBottom: '5px' }}>
              <button className="btn btn-outline-dark" onClick={handleGoBack}>Go Back</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default UclStandings;
