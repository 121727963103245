import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get, update, push, onValue } from 'firebase/database';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useAuth } from '../firebase';
import { FaClipboard } from "react-icons/fa";
import ScottishPremiershipPicksBracket from './ScottishPremiershipPicksBracket';
import Euro2024Fixtures from './Euro2024Fixtures'
import Euro2024Picks from './Euro2024Picks';
import Euro2024FixturesBracket from './Euro2024FixturesBracket';
import Euro2024PicksBracket from './Euro2024PicksBracket';
import { useTranslation } from 'react-i18next';
import ScottishFixtures24 from './ScottishFixtures24';
import ScottishPicks24 from './ScottishPicks24';
import FixturesForJoinedBracket from './FixturesForJoinedBracket';
import PremierLeagueForJoinedBrackets from './PremierLeagueForJoinedBracket';
import WorldCup2022PicksBracket from './WorldCup2022PicksBracket';
import ScottishFixturesBracket from './ScottishFixturesBracket';
import ChampionshipPicks from './ChampionshipPicks';
import ChampionshipFixtures from './ChampionshipFixtures';
import ChampionshipPicksBracket from './ChampionshipPicksBracket';
import ChampionshipFixturesBracket from './ChampionshipFixturesBracket';
import {  FaFacebook } from 'react-icons/fa'; // Import FaFacebook icon
import LaLigaPicksBracket from './LaLigaPicksBracket';
import LaLigaFixturesBracket from './LaLigaFixturesBracket';
import UCLPicksBracket from './UCLPicksBracket';
import UclFixture from './UclFixture';
import UclFixtureBracket from './UclFixtureBracket';
import BundesligaFixturesBracket from './BundesligaFixturesBracket';
import BundesligaPicksBracket from './BundesligaPicksBracket';
import SerieAPicksBracket from './SerieAPicksBracket';
import SerieAFixtures from './SerieAFixtures';
import SerieAFixturesBracket from './SerieAFixturesBracket';
import SASoccerPicksBracket from './SASoccerPicksBracket';
import SASoccerFixturesBracket from './SASoccerFixturesBracekt';

function JoinedBracket() {
    const { nameOfBracket } = useParams();
    const [bracketParticipants, setBracketParticipants] = useState([]);
    const [participantsPoints, setParticipantsPoints] = useState([]);
    const { auth } = useAuth();
    const [user, setUser] = useState(null);
    const { t } = useTranslation();
    const database = getDatabase();
    const [copySuccess, setCopySuccess] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [invitedEmails, setInvitedEmails] = useState([]);
    const [invitationSent, setInvitationSent] = useState(false);
    const [invitationMessageSent, setInvitationMessageSent] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const [image, setImage] = useState('');
    const [captainName, setCaptainName] = useState('');
    const [purpose, setPurpose] = useState('');
    const [location, setLocation] = useState('');
    const [participantsCount, setParticipantsCount] = useState('');
    const [description, setDescription] = useState('');
    const [tournament, setTournament] = useState('');
    const [bracketId, setBracketId] = useState('');
    const [error, setError] = useState(false);
    const invitationText = "You have been invited to join a friends bracket. Click here to view your invitation: https://predictify.app/dashboard";
    const [hasJoined, setHasJoined] = useState(false);
    const [copied, setCopied] = useState(false);
    const [participants, setParticipants] = useState([]);
    const navigate = useNavigate();
    const [showFacebookButton, setShowFacebookButton] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    useEffect(() => {
        const fetchBracketParticipants = async () => {
            try {
                const bracketsRef = ref(database, 'CreateBracket');
                const snapshot = await get(bracketsRef);
                const data = snapshot.val();
                if (data) {
                    const bracket = Object.values(data).find(bracket => bracket.nameOfBracket === nameOfBracket);
                    if (bracket) {
                        const participants = Object.values(bracket.participants || {});
                        setBracketParticipants(participants);
                    }
                }
            } catch (error) {
                console.error('Error fetching bracket data:', error);
            }
        };

        fetchBracketParticipants();
    }, [nameOfBracket, database]);

    useEffect(() => {
        const fetchParticipantsPoints = async () => {
            try {
                let pointsRef;
                if (tournament === 'EURO 2024') {
                    pointsRef = ref(database, 'EURO2024TotalPoints');
                }else if (tournament === 'Championship') {
                    pointsRef = ref(database, 'Championship2024TotalPoints');
                }
                else if (tournament === 'Serie A') {
                    pointsRef = ref(database, 'SerieATotalPoints');
                }
                else if (tournament === 'UCL') {
                    pointsRef = ref(database, 'UCL2024TotalPoints');
                } 
                else if (tournament === 'Bundesliga') {
                    pointsRef = ref(database, 'BundesligaTotalPoints');
                }
                else if (tournament === 'SASoccer') {
                    pointsRef = ref(database, 'SASoccerTotalPoints');
                } else if (tournament === 'Scottish Premiership') {
                    pointsRef = ref(database, 'Scottish2024TotalPoints');
                }
                 else if (tournament === 'English Premier League') {
                    pointsRef = ref(database, 'EPL2024TotalPoints');
                } else if (tournament === 'La Liga') {
                    pointsRef = ref(database, 'LaLiga2024TotalPoints');
                }
                const snapshot = await get(pointsRef);
                const pointsData = snapshot.val() || {};
                const participants = Object.keys(pointsData).map(userID => ({
                    userID,
                    displayName: extractUsernameFromDisplayName(userID),
                    totalPoints: pointsData[userID].totalPoints || 0
                }));
                participants.sort((a, b) => b.totalPoints - a.totalPoints);
                setParticipantsPoints(participants);
            } catch (error) {
                console.error('Error fetching points data:', error);
            }
        };

        if (tournament) {
            fetchParticipantsPoints();
        }
    }, [database, tournament]);

    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return '';
        }

        const match = displayName.match(/\(([^)]+)\)/);
        return match ? match[1] : displayName.trim();
    };

    const getParticipantPoints = (username) => {
        const participant = participantsPoints.find(participant => participant.displayName === username);
        return participant ? participant.totalPoints : 0;
    };

    const combinedParticipants = bracketParticipants.map(participant => ({
        ...participant,
        username: extractUsernameFromDisplayName(participant.displayName),
        points: getParticipantPoints(extractUsernameFromDisplayName(participant.displayName))
    })).sort((a, b) => b.points - a.points);

    const viewAll = () => {
        setShowAll(true);
    };

    const handleLeaveBracket = async () => {
        try {
            const userId = auth.currentUser.uid;
            const bracketRef = ref(database, `CreateBracket/${bracketId}`);
            const snapshot = await get(bracketRef);
            const bracketData = snapshot.val();

            if (bracketData.participants && bracketData.participants[userId]) {
                if (bracketData.userId === userId) {
                    return;
                }

                delete bracketData.participants[userId];
                const updatedParticipantsCount = Object.keys(bracketData.participants).length;

                await update(bracketRef, {
                    participantsCount: updatedParticipantsCount,
                    participants: bracketData.participants
                });

                setHasJoined(false);
                navigate('/joinhuddle');
            }
        } catch (error) {
            console.error('Error leaving bracket:', error);
        }
    };

    const sendInvitation = async () => {
        try {
            const emailExists = participants.some(participant => participant.email === inviteEmail);

            if (emailExists) {
                setError('User is already in this Huddle');
                setTimeout(() => {
                    setError('');
                }, 5000);
                return;
            }
            if (!inviteEmail) {
                setError('Please enter an email');
                setTimeout(() => {
                    setError('');
                    return;
                }, 5000);
            }
            if (!inviteEmail.includes('@')) {
                setError('Invalid email');
                setTimeout(() => {
                    setError('');
                }, 5000);
                return;
            }

            const invitationsRef = ref(database, 'invitations');
            const snapshot = await get(invitationsRef);
            const invitationsData = snapshot.val();

            if (invitationsData) {
                const existingInvitation = Object.values(invitationsData).find(invitation => invitation.inviteEmail === inviteEmail && invitation.bracketId === bracketId);
                if (existingInvitation) {
                    setError('Invitation has already been sent to this user');
                    setTimeout(() => {
                        setError('');
                    }, 5000);
                    return;
                }
            }

            const newInvitation = {
                inviteEmail: inviteEmail,
                bracketId: bracketId,
                status: 'pending',
            };

            await push(invitationsRef, newInvitation);

            setInvitedEmails(prevInvitedEmails => [...prevInvitedEmails, inviteEmail]);

            setInvitationSent(true);
            setInvitationMessageSent(true);
            setTimeout(() => {
                setInvitationMessageSent('');
            }, 3000);
            return;
        } catch (error) {
            console.error('Error sending invitation:', error);
        }
    };

    const copyToClipboard = async () => {
        try {
            const currentUrl = window.location.href; // Get the current page URL
            await navigator.clipboard.writeText(currentUrl);
            setCopied(true);
            setShowFacebookButton(true);
            setTimeout(() => setCopied(false), 3000);
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    const openFacebookPost = () => {
        const currentUrl = window.location.href;
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}&quote=${encodeURIComponent('Hey, join my huddle!')}`;
        window.open(facebookShareUrl, '_blank');
    };

    const handleJoinBracket = async () => {
        try {
            const userId = auth.currentUser.uid;
            const user = auth.currentUser;
            const userDisplayName = user.displayName;

            const bracketRef = ref(database, `CreateBracket/${bracketId}`);
            const snapshot = await get(bracketRef);
            const bracketData = snapshot.val();

            if (bracketData.participants && bracketData.participantsCount < bracketData.maxAmountOfPlayers) {
                const updatedParticipants = {
                    ...bracketData.participants,
                    [userId]: {
                        displayName: userDisplayName,
                        email: user.email,
                        userId: userId,
                    },
                };

                const updatedParticipantsCount = Object.keys(updatedParticipants).length;

                await update(bracketRef, {
                    participantsCount: updatedParticipantsCount,
                    participants: updatedParticipants
                });

                setHasJoined(true);
                // navigate(`/brackets/joined/${bracketData.nameOfBracket}`);
            }
        } catch (error) {
            console.error('Error joining bracket:', error);
        }
    };

    useEffect(() => {
        const fetchBracketData = async () => {
            try {
                const bracketsRef = ref(database, 'CreateBracket');
                onValue(bracketsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const bracket = Object.values(data).find(bracket => bracket.nameOfBracket === nameOfBracket);
                        if (bracket) {
                            setCaptainName(bracket.captainsName);
                            setDescription(bracket.description);
                            setLocation(bracket.location);
                            setParticipantsCount(bracket.participantsCount);
                            setPurpose(bracket.purpose);
                            setImage(bracket.image);
                            setTournament(bracket.tournament)
                            setParticipants(Object.values(bracket.participants));
                            setBracketId(Object.keys(data).find(key => data[key] === bracket));

                            const userId = auth.currentUser.uid;
                            if (bracket.participants && bracket.participants[userId]) {
                                setHasJoined(true);
                            } else {
                                setHasJoined(false);
                            }
                        }
                    }
                });
            } catch (error) {
                console.error('Error fetching bracket data:', error);
            }
        };
        fetchBracketData();
    }, [nameOfBracket, auth.currentUser]);

    return (
        <div>
        <Navbar />
        <div className='App-headerV3'>
            <section className="bg-transparent py-3 py-md-5 py-xl-8">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <h2 className="mb-4 display-5 text-center text-dark">{nameOfBracket}</h2>
                            <p className="text-secondary text-center lead fs-4 mb-5 text-dark">
                            {t('hello')} {extractUsernameFromDisplayName(user?.displayName)},{t('welcome_to')} {nameOfBracket}
                            </p>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                        </div>
                    </div>
                </div>
    
                <div className="container">
                    <div className="row gy-4 gy-lg-0">
                        <div className="col-12 col-lg-4 col-xl-3">
                            <div className="row gy-4">
                                <div className="col-12">
                                    <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header purple-bg text-light text-center">{t('welcome_to')} {nameOfBracket}</div>
                                        <div className="card-body">
                                            <div className="text-center mb-3">
                                                <img src={image} alt={nameOfBracket} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            </div>
                                            <h5 className="text-center mb-1">{captainName}</h5>
                                            <p className="text-center text-secondary mb-4">{t('huddle_captain')}</p>
                                            <ul className="list-group list-group-flush mb-4">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <h6 className="m-0">{t('participants')}</h6>
                                                    <span>{participantsCount}</span>
                                                </li>
                                            </ul>
                                            <div className="d-grid m-0">
  <button
    className={`btn ${hasJoined ? 'btn-outline-danger' : 'btn-outline-success'}`}
    type="button"
    onClick={hasJoined ? handleLeaveBracket : handleJoinBracket}
  >
    {hasJoined ? t('leave') : t('join')}
  </button>
</div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header purple-bg text-light text-center">{t('about_huddle')}
</div>
                                        <div className="card-body">
                                            <ul className="list-group list-group-flush mb-0">
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bii bi-geo-alt-fill me-2"></span>
                                                        {t('location')}

                                                    </h6>
                                                    <span>{location}</span><br />
                                                </li>
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bi bi-patch-question-fill me-2"></span>
                                                        {t('purpose')}

                                                    </h6>
                                                    <span>{purpose}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bi bi-grid-fill me-2"></span>
                                                        {t('huddle_id')}
                                                    </h6>
                                                    <span>{bracketId}</span><br />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-9">
                            <div className="card widget-card border-light shadow-sm">
                                <div className="card-body p-4">
                                <ul className="nav nav-tabs" id="profileTab" role="tablist">
    <li className="nav-item" role="presentation">
        <button className="nav-link active text-purple" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">{t('overview_tab')}</button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="nav-link text-purple" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('your_picks_tab')}</button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="nav-link text-purple" id="email-tab" data-bs-toggle="tab" data-bs-target="#email-tab-pane" type="button" role="tab" aria-controls="email-tab-pane" aria-selected="false">{t('fixtures_tab')}</button>
    </li>
    <li className="nav-item" role="presentation">
        <button className="nav-link text-purple" id="participants-tab" data-bs-toggle="tab" data-bs-target="#participants-tab-pane" type="button" role="tab" aria-controls="participants-tab-pane" aria-selected="false">{t('participants_tab')}</button>
    </li>
</ul>

                                    <div className="tab-content pt-4" id="profileTabContent">
                                        <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                        <h5 className="mb-3">{t('about')}</h5>
<p className="lead mb-3">{description}</p>
<hr className="w-90 mx-auto mb-3 mb border-dark-subtle" />
<h5 className="mb-3">{t('how_huddle_work')}</h5>

                                            <div className="row g-0 mb-3">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
        <div className="p-2">{t('pick_correct_score')}</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
        <div className="p-2">{t('3_points')}</div>
    </div>
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
        <div className="p-2">{t('pick_winning_team')}</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
        <div className="p-2">{t('1_point')}</div>
    </div>
</div>

<h5 className="mb-3 padtop">{t('invite_friends1')}</h5>
<hr className="w-90 mx-auto mb-1 mb border-dark-subtle" />
<p className="mb-1 padtop">Use the below buttons share and invite your friends to join in the fun!</p>
<p className="mb-1 padtop">Send and Invite Link directly using your preferred messaging service or share the link to Facebook!</p>

<div className="container">
            <div className="row g-0 align-items-center">
                {/* <div className="mt-2 d-flex gap-2 padbot">
                    <input
                        className="form-control"
                        placeholder="email@gmail.com"
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                    />
                    <button className="btn btn-outline-dark" onClick={sendInvitation}>Invite</button>
                </div> */}
                <div className="mt-2 d-flex gap-2 padbot">
                    <button className="btn btn-outline-dark" onClick={copyToClipboard}>
                        <FaClipboard /> Copy Invite Link
                    </button>
                   
                        <button className="btn btn-outline-primary" onClick={openFacebookPost}>
                            <FaFacebook /> Share on Facebook
                        </button>
                    
                </div>
            </div>
            {error && <p className="text-danger mt-3">{error}</p>}
            {invitationMessageSent && <p className="text-success mt-3">Invitation sent!</p>}
        </div>

                                        </div>
                                        <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                            {tournament === 'EURO 2024' && <Euro2024PicksBracket />}
                                            {tournament === 'Scottish Premiership' && <ScottishPremiershipPicksBracket />}
                                            {tournament === 'English Premier League' && <PremierLeagueForJoinedBrackets />}
                                            {tournament === 'World Cup 2022' && <WorldCup2022PicksBracket />}
                                            {tournament === 'Championship' && <ChampionshipPicksBracket />}
                                            {tournament === 'Serie A' && <SerieAPicksBracket />}

                                            {tournament === 'La Liga' && <LaLigaPicksBracket />}
                                            {tournament === 'UCL' && <UCLPicksBracket />}
                                            {tournament === 'Bundesliga' && <BundesligaPicksBracket />}

                                            {tournament === 'SASoccer' && <SASoccerPicksBracket />}


                                        </div>
                                        <div className="tab-pane fade" id="email-tab-pane" role="tabpanel" aria-labelledby="email-tab" tabIndex="0">
                                            {tournament === 'EURO 2024' && <Euro2024FixturesBracket />}
                                            {tournament === 'Scottish Premiership' && <ScottishFixturesBracket />}
                                            {tournament === 'English Premier League' && <FixturesForJoinedBracket />}
                                            {tournament === 'La Liga' && <LaLigaFixturesBracket />}
                                            {tournament === 'UCL' && <UclFixtureBracket />}
                                            {tournament === 'Championship' && <ChampionshipFixturesBracket />}
                                            {tournament === 'Bundesliga' && <BundesligaFixturesBracket />}
                                            {tournament === 'Serie A' && <SerieAFixturesBracket />}
                                            {tournament === 'SASoccer' && <SASoccerFixturesBracket />}



                                        </div>
                                        <div className="tab-pane fade" id="participants-tab-pane" role="tabpanel" aria-labelledby="participants-tab" tabIndex="0">
    <div className="card widget-card border-light shadow-sm mb-4">
        <div className="card-header purple-bg text-light">Huddle Leaderboard</div>
        <div className="card-body">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t('player')}</th>
                        <th scope="col">{t('points')}</th>
                    </tr>
                </thead>
                <tbody>
                    {combinedParticipants.slice(0, showAll ? combinedParticipants.length : 5).map((participant, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{participant.username}</td>
                            <td>{participant.points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {!showAll && combinedParticipants.length > 5 && (
                <button className="btn btn-outline-dark mt-3" onClick={viewAll}>{t('View All')}</button>
            )}
        </div>
    </div>
</div>

                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
                                    <Footer />
    </div>
    );
}

export default JoinedBracket;
