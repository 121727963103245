


import React, { useState, useEffect } from 'react';
import { ref, getDatabase, set, get } from 'firebase/database';
import { Spinner } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import Monaco from '../Team-Kits-Images/Monaco.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Benfica from '../Team-Kits-Images/Benfica.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Brest from '../Team-Kits-Images/Brest.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Brugge from '../Team-Kits-Images/Brugge.png'
import Zagreb from '../Team-Kits-Images/Zagreb.png'
import Feyenoord from '../Team-Kits-Images/Feyenoord.png'
import Girona from '../Team-Kits-Images/Girona.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Lille from '../Team-Kits-Images/Lille.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import PSG from '../Team-Kits-Images/PSG.png'
import PSV from '../Team-Kits-Images/PSV.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Belgrade from '../Team-Kits-Images/Belgrade.png'
import Salzburg from '../Team-Kits-Images/Salzburg.png'
import Shakhtar from '../Team-Kits-Images/Shakhtar.png'
import Bratislava from '../Team-Kits-Images/Bratislava.png'
import Praha from '../Team-Kits-Images/Prague.png'
import Sporting from '../Team-Kits-Images/Sporting.png'
import Graz from '../Team-Kits-Images/Graz.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import YoungBoys from '../Team-Kits-Images/YoungBoys.png'
import { useTranslation } from 'react-i18next';



function UclFixture() {
    const [fixtures, setFixtures] = useState([]);
    const [inputsData, setInputsData] = useState({});
    const [selectedRound, setSelectedRound] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const { t } = useTranslation();


    useEffect(() => {
        const fetchFixturesAndPicks = async () => {
            try {
                // Fetch fixtures data from the API
                const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/2024", {
                    headers: {
                        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                    }
                });
                const fixturesData = await fixturesResponse.json();
                console.log("Fetched fixtures data:", fixturesData);
    
                // Fetch user's picks
                if (userId) {
                    const picksRef = ref(getDatabase(), `picks/${userId}`);
                    const snapshot = await get(picksRef);
                    const picksData = snapshot.val();
    
                    if (picksData) {
                        setInputsData(picksData);
                    }
                }
    
                // Find the current round
                const now = new Date();
                const currentRound = rounds.find(round => now >= round.startDate && now <= round.endDate);
                console.log("Current Round:", currentRound); // Log the current round to verify
    
                // Filter fixtures for the current round
                const fixturesForCurrentRound = fixturesData.filter(fixture => {
                    const fixtureDate = new Date(fixture.fixture.date);
                    return fixtureDate >= currentRound.startDate && fixtureDate <= currentRound.endDate;
                });
    
                // Sort the fixtures for the current round by date
                fixturesForCurrentRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
                // Set the fixtures state with the fixtures for the current round
                setFixtures(fixturesForCurrentRound);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchFixturesAndPicks();
    }, [userId]);

    const handleInputChange = (id, homeTeam, awayTeam) => {
        setInputsData(prevInputsData => ({
            ...prevInputsData,
            [id]: { homeTeam, awayTeam }
        }));
    };

    const convertToFormattedDateTime = (dateString) => {
        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const rounds = [
        { number: 1, startDate: new Date('2024-09-15'), endDate: new Date('2024-09-20') },
        { number: 2, startDate: new Date('2024-09-20'), endDate: new Date('2024-10-03') },
        { number: 3, startDate: new Date('2024-10-03'), endDate: new Date('2024-10-24') },
        { number: 4, startDate: new Date('2024-10-24'), endDate: new Date('2024-11-07') },
        { number: 5, startDate: new Date('2024-11-07'), endDate: new Date('2024-11-28') },
        { number: 6, startDate: new Date('2024-11-28'), endDate: new Date('2024-12-12') },
        { number: 7, startDate: new Date('2024-12-12'), endDate: new Date('2025-01-23') },
        { number: 8, startDate: new Date('2025-01-23'), endDate: new Date('2025-01-30') },

    ];
    

    const handleClickRound = async (roundNumber) => {
        const selectedRound = rounds.find(round => round.number === roundNumber);
        console.log("Selected Round:", selectedRound); // Log the selected round to verify
        
        try {
            const startDate = selectedRound.startDate.toISOString().slice(0, 10);
            const endDate = selectedRound.endDate.toISOString().slice(0, 10);
    
            const response = await fetch(`?from=${startDate}&to=${endDate}`, {
                headers: {
                    'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
                }
            });
    
            const data = await response.json();
            console.log(`Fetched data for round ${roundNumber}:`, data); // Log the fetched data to check its structure
    
            // Filter the fetched fixtures based on the selected round's date range
            const fixturesForRound = data.filter(fixture => {
                const fixtureDate = new Date(fixture.fixture.date);
                return fixtureDate >= selectedRound.startDate && fixtureDate <= selectedRound.endDate;
            });
    
            // Sort fixtures within the round by date
            fixturesForRound.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
    
            // Set fixtures state with fixturesForRound
            setFixtures(fixturesForRound);
        } catch (error) {
            console.error(`Error fetching data for round ${roundNumber}:`, error);
        }
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Find the round that matches the current date
        const currentRound = rounds.find(round => currentDate >= round.startDate && currentDate <= round.endDate);
    
        if (currentRound) {
            // Fetch fixtures for the current round
            handleClickRound(currentRound.number);
            setSelectedRound(currentRound.number);
        } else {
            console.log("No round matches the current date.");
        }
    }, []);
    

    
    
    return (
        <div className='App-headerV3'>
            <Navbar />
            <div className='App-headerV3'>
            <h2 className="text-dark text-center padtop mb-3">UCL Fixtures</h2>
            {fixtures.length > 0 && ( // Check if fixtures exist before rendering dropdown
                <div className="dropdown-center text-center padbot">
                    <button className="btn btn-custom dropdown-toggle btn mb-2 text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {selectedRound !== 'N/A' ? `Round ${selectedRound}` : 'Select Round'} {/* Display selected round dynamically */}
                    </button>
                    <ul className="dropdown-menu text-center custom" aria-labelledby="dropdownMenuButton1">
                        {/* Render dropdown items for each round */}
                        {rounds.map((round) => (
                            <li key={round.number}>
                                <a className="dropdown-item" onClick={() => {handleClickRound(round.number); setSelectedRound(round.number);}}>{`Round ${round.number}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

                <div className="container mb-3 " style={{marginBottom: '15px'}}>
                    <div className='mb-2 '>
                       
                            <div className='mb-2'>
                                {fixtures.map(fixture => (
                                    <div key={fixture.fixture.id} className='mb-3 col-lg-12 mx-auto card' style={{ backgroundColor: '#fff' }}>
                                        <p className='text-center padtop'>{fixture.league.name}</p>
                                        <div className="row ">
                                        <div className="col text-center "> 
                                        {/* Display kit of home team */}
                                        {fixture.teams.home.name === 'Bayern München' ? (
    <img src={BayernMunich} alt="Bayern München" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Aston Villa' ? (
    <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Liverpool' ? (
    <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Juventus' ? (
    <img src={Juventus} alt="Juventus" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Atletico Madrid' ? (
    <img src={Athleti} alt="Atletico Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Real Madrid' ? (
    <img src={Madrid} alt="Real Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Sporting CP' ? (
    <img src={Sporting} alt="Sporting CP" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Arsenal' ? (
    <img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Atalanta' ? (
    <img src={Atalanta} alt="Atalanta" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Barcelona' ? (
    <img src={Barcelona} alt="Barcelona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Bayer Leverkusen' ? (
    <img src={Leverkusen} alt="Bayer Leverkusen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Benfica' ? (
    <img src={Benfica} alt="Benfica" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Bologna' ? (
    <img src={Bologna} alt="Bologna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Borussia Dortmund' ? (
    <img src={Dortmund} alt="Borussia Dortmund" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Stade Brestois 29' ? (
    <img src={Brest} alt="Stade Brestois 29" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Celtic' ? (
    <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Club Brugge KV' ? (
    <img src={Brugge} alt="Club Brugge KV" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Feyenoord' ? (
    <img src={Feyenoord} alt="Feyenoord" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Girona' ? (
    <img src={Girona} alt="Girona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Inter' ? (
    <img src={Inter} alt="Inter" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Manchester City' ? (
    <img src={ManCity} alt="Manchester City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Monaco' ? (
    <img src={Monaco} alt="Monaco" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Paris Saint Germain' ? (
    <img src={PSG} alt="Paris Saint Germain" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'RB Leipzig' ? (
    <img src={Leipzig} alt="RB Leipzig" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'FK Crvena Zvezda' ? (
    <img src={Belgrade} alt="FK Crvena Zvezda" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Red Bull Salzburg' ? (
    <img src={Salzburg} alt="Red Bull Salzburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Shakhtar Donetsk' ? (
    <img src={Shakhtar} alt="Shakhtar Donetsk" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Slovan Bratislava' ? (
    <img src={Bratislava} alt="Slovan Bratislava" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Sparta Praha' ? (
    <img src={Praha} alt="Sparta Praha" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Sturm Graz' ? (
    <img src={Graz} alt="Sturm Graz" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'PSV Eindhoven' ? (
    <img src={PSV} alt="PSV Eindhoven" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'VfB Stuttgart' ? (
    <img src={Stuttgart} alt="VfB Stuttgart" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'AC Milan' ? (
    <img src={Milan} alt="AC Milan" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Lille' ? (
    <img src={Lille} alt="Lille" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'BSC Young Boys' ? (
    <img src={YoungBoys} alt="BSC Young Boys" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.home.name === 'Dinamo Zagreb' ? (
    <img src={Zagreb} alt="Dinamo Zagreb" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                        {/* Display kit of home team */}
                                        </div>


                                        <>
  <div className="col padtop1 ">
    <input
      className="form-control text-center fs-3 "
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
      disabled
    />
  </div>
  <div className="col padtop1">
    <input
      className="form-control text-center fs-3"
      style={{ backgroundColor: '#fff' }}
      value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
      disabled
    />
  </div>
</>



                                            <div className="col text-center"> 
                                         {/* Display kit of away team */} 
                                         {' '}
                                         {fixture.teams.away.name === 'Bayern München' ? (
    <img src={BayernMunich} alt="Bayern München" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Aston Villa' ? (
    <img src={AstonVilla} alt="Aston Villa" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Liverpool' ? (
    <img src={Liverpool} alt="Liverpool" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Juventus' ? (
    <img src={Juventus} alt="Juventus" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Atletico Madrid' ? (
    <img src={Athleti} alt="Atletico Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Real Madrid' ? (
    <img src={Madrid} alt="Real Madrid" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Sporting CP' ? (
    <img src={Sporting} alt="Sporting CP" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Arsenal' ? (
    <img src={Arsenal} alt="Arsenal" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Atalanta' ? (
    <img src={Atalanta} alt="Atalanta" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Barcelona' ? (
    <img src={Barcelona} alt="Barcelona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Bayer Leverkusen' ? (
    <img src={Leverkusen} alt="Bayer Leverkusen" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Benfica' ? (
    <img src={Benfica} alt="Benfica" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Bologna' ? (
    <img src={Bologna} alt="Bologna" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Borussia Dortmund' ? (
    <img src={Dortmund} alt="Borussia Dortmund" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Stade Brestois 29' ? (
    <img src={Brest} alt="Stade Brestois 29" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Celtic' ? (
    <img src={Celtic} alt="Celtic" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Club Brugge KV' ? (
    <img src={Brugge} alt="Club Brugge KV" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Feyenoord' ? (
    <img src={Feyenoord} alt="Feyenoord" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Girona' ? (
    <img src={Girona} alt="Girona" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Inter' ? (
    <img src={Inter} alt="Inter" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Manchester City' ? (
    <img src={ManCity} alt="Manchester City" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Monaco' ? (
    <img src={Monaco} alt="Monaco" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Paris Saint Germain' ? (
    <img src={PSG} alt="Paris Saint Germain" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'RB Leipzig' ? (
    <img src={Leipzig} alt="RB Leipzig" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'FK Crvena Zvezda' ? (
    <img src={Belgrade} alt="FK Crvena Zvezda" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Red Bull Salzburg' ? (
    <img src={Salzburg} alt="Red Bull Salzburg" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Shakhtar Donetsk' ? (
    <img src={Shakhtar} alt="Shakhtar Donetsk" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Slovan Bratislava' ? (
    <img src={Bratislava} alt="Slovan Bratislava" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Sparta Praha' ? (
    <img src={Praha} alt="Sparta Praha" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Sturm Graz' ? (
    <img src={Graz} alt="Sturm Graz" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'PSV Eindhoven' ? (
    <img src={PSV} alt="PSV Eindhoven" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'VfB Stuttgart' ? (
    <img src={Stuttgart} alt="VfB Stuttgart" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'AC Milan' ? (
    <img src={Milan} alt="AC Milan" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Lille' ? (
    <img src={Lille} alt="Lille" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'BSC Young Boys' ? (
    <img src={YoungBoys} alt="BSC Young Boys" style={{ width: '75px', height: 'auto' }} />
) : fixture.teams.away.name === 'Dinamo Zagreb' ? (
    <img src={Zagreb} alt="Dinamo Zagreb" style={{ width: '75px', height: 'auto' }} />
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                        {/* Display kit of away team */}
                                        </div>              
                                                                      <div className="container">
                                                <div className="row">
                                                    <div className="col text-center">
                                                 
                                                                                                                {/* Display home team Initials */}

                                                                                                                {fixture.teams.home.name === 'Arsenal' ? (
    <h5>AFC</h5>
) : fixture.teams.home.name === 'Aston Villa' ? (
    <h5>AVFC</h5>
) : fixture.teams.home.name === 'Bayern München' ? (
    <h5>BAY</h5>
) : fixture.teams.home.name === 'Liverpool' ? (
    <h5>LFC</h5>
) : fixture.teams.home.name === 'Juventus' ? (
    <h5>JUV</h5>
) : fixture.teams.home.name === 'Atletico Madrid' ? (
    <h5>ATM</h5>
) : fixture.teams.home.name === 'Real Madrid' ? (
    <h5>RM</h5>
) : fixture.teams.home.name === 'Sporting CP' ? (
    <h5>SCP</h5>
) : fixture.teams.home.name === 'Atalanta' ? (
    <h5>ATL</h5>
) : fixture.teams.home.name === 'Barcelona' ? (
    <h5>BAR</h5>
) : fixture.teams.home.name === 'Bayer Leverkusen' ? (
    <h5>B04</h5>
) : fixture.teams.home.name === 'Benfica' ? (
    <h5>SLB</h5>
) : fixture.teams.home.name === 'Bologna' ? (
    <h5>BFC</h5>
) : fixture.teams.home.name === 'Borussia Dortmund' ? (
    <h5>BVB</h5>
) : fixture.teams.home.name === 'Stade Brestois 29' ? (
    <h5>SB29</h5>
) : fixture.teams.home.name === 'Celtic' ? (
    <h5>CEL</h5>
) : fixture.teams.home.name === 'Club Brugge KV' ? (
    <h5>BRU</h5>
) : fixture.teams.home.name === 'Feyenoord' ? (
    <h5>FEY</h5>
) : fixture.teams.home.name === 'Girona' ? (
    <h5>GIR</h5>
) : fixture.teams.home.name === 'Inter' ? (
    <h5>INT</h5>
) : fixture.teams.home.name === 'Manchester City' ? (
    <h5>MCFC</h5>
) : fixture.teams.home.name === 'Monaco' ? (
    <h5>ASM</h5>
) : fixture.teams.home.name === 'Paris Saint Germain' ? (
    <h5>PSG</h5>
) : fixture.teams.home.name === 'RB Leipzig' ? (
    <h5>RBL</h5>
) : fixture.teams.home.name === 'FK Crvena Zvezda' ? (
    <h5>CRV</h5>
) : fixture.teams.home.name === 'Red Bull Salzburg' ? (
    <h5>RBS</h5>
) : fixture.teams.home.name === 'Shakhtar Donetsk' ? (
    <h5>FCD</h5>
) : fixture.teams.home.name === 'Slovan Bratislava' ? (
    <h5>SLB</h5>
) : fixture.teams.home.name === 'Sparta Praha' ? (
    <h5>SPA</h5>
) : fixture.teams.home.name === 'Sturm Graz' ? (
    <h5>STU</h5>
) : fixture.teams.home.name === 'PSV Eindhoven' ? (
    <h5>PSV</h5>
) : fixture.teams.home.name === 'VfB Stuttgart' ? (
    <h5>VFB</h5>
) : fixture.teams.home.name === 'AC Milan' ? (
    <h5>ACM</h5>
) : fixture.teams.home.name === 'Lille' ? (
    <h5>LOSC</h5>
) : fixture.teams.home.name === 'BSC Young Boys' ? (
    <h5>YB</h5>
) : fixture.teams.home.name === 'Dinamo Zagreb' ? (
    <h5>DIN</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.home.name}
    </span>
)}

                                                                                                                                                                                                                                     {/* Display home team Initials */}
                                                                                       {/* Display home team Initials */}

                                                    </div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center"></div>
                                                    <div className="col text-center">
                                                    {fixture.teams.away.name === 'Arsenal' ? (
    <h5>AFC</h5>
) : fixture.teams.away.name === 'Aston Villa' ? (
    <h5>AVFC</h5>
) : fixture.teams.away.name === 'Bayern München' ? (
    <h5>BAY</h5>
) : fixture.teams.away.name === 'Liverpool' ? (
    <h5>LFC</h5>
) : fixture.teams.away.name === 'Juventus' ? (
    <h5>JUV</h5>
) : fixture.teams.away.name === 'Atletico Madrid' ? (
    <h5>ATM</h5>
) : fixture.teams.away.name === 'Real Madrid' ? (
    <h5>RM</h5>
) : fixture.teams.away.name === 'Sporting CP' ? (
    <h5>SCP</h5>
) : fixture.teams.away.name === 'Atalanta' ? (
    <h5>ATL</h5>
) : fixture.teams.away.name === 'Barcelona' ? (
    <h5>BAR</h5>
) : fixture.teams.away.name === 'Bayer Leverkusen' ? (
    <h5>B04</h5>
) : fixture.teams.away.name === 'Benfica' ? (
    <h5>SLB</h5>
) : fixture.teams.away.name === 'Bologna' ? (
    <h5>BFC</h5>
) : fixture.teams.away.name === 'Borussia Dortmund' ? (
    <h5>BVB</h5>
) : fixture.teams.away.name === 'Stade Brestois 29' ? (
    <h5>SB29</h5>
) : fixture.teams.away.name === 'Celtic' ? (
    <h5>CEL</h5>
) : fixture.teams.away.name === 'Club Brugge KV' ? (
    <h5>BRU</h5>
) : fixture.teams.away.name === 'Feyenoord' ? (
    <h5>FEY</h5>
) : fixture.teams.away.name === 'Girona' ? (
    <h5>GIR</h5>
) : fixture.teams.away.name === 'Inter' ? (
    <h5>INT</h5>
) : fixture.teams.away.name === 'Manchester City' ? (
    <h5>MCFC</h5>
) : fixture.teams.away.name === 'Monaco' ? (
    <h5>ASM</h5>
) : fixture.teams.away.name === 'Paris Saint Germain' ? (
    <h5>PSG</h5>
) : fixture.teams.away.name === 'RB Leipzig' ? (
    <h5>RBL</h5>
) : fixture.teams.away.name === 'FK Crvena Zvezda' ? (
    <h5>CRV</h5>
) : fixture.teams.away.name === 'Red Bull Salzburg' ? (
    <h5>RBS</h5>
) : fixture.teams.away.name === 'Shakhtar Donetsk' ? (
    <h5>FCD</h5>
) : fixture.teams.away.name === 'Slovan Bratislava' ? (
    <h5>SLB</h5>
) : fixture.teams.away.name === 'Sparta Praha' ? (
    <h5>SPA</h5>
) : fixture.teams.away.name === 'Sturm Graz' ? (
    <h5>STU</h5>
) : fixture.teams.away.name === 'PSV Eindhoven' ? (
    <h5>PSV</h5>
) : fixture.teams.away.name === 'VfB Stuttgart' ? (
    <h5>VFB</h5>
) : fixture.teams.away.name === 'AC Milan' ? (
    <h5>ACM</h5>
) : fixture.teams.away.name === 'Lille' ? (
    <h5>LOSC</h5>
) : fixture.teams.away.name === 'BSC Young Boys' ? (
    <h5>YB</h5>
) : fixture.teams.away.name === 'Dinamo Zagreb' ? (
    <h5>DIN</h5>
) : (
    <span style={{ fontStyle: 'normal' }}>
        {fixture.teams.away.name}
    </span>
)}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}</p>
                                    </div>
                                ))}
                                                                    <div></div>

                            </div>
                    </div>
                </div>
            </div>
{/* <div className='mb-2 padtop'></div> */}
            <Footer />
        </div>
    );
}

export default UclFixture;
