import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function Standings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};

    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
      standingsMap[fixture.teams.away.name] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;
      let homeResult = "";
      let awayResult = "";

      if (homeGoals > awayGoals) {
        homePoints = 3;
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }

      // Update standings
      standingsMap[homeTeam].points += homePoints;
      standingsMap[awayTeam].points += awayPoints;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
    });

    // Convert standings map to array
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Arsenal':
        return Arsenal;
      case 'Aston Villa':
        return AstonVilla;
      case 'Bournemouth':
        return Bournemouth;
      case 'Brentford':
        return Brentford;
      case 'Brighton':
        return Brighton;
      case 'Burnley':
        return Burnley;
      case 'Chelsea':
        return Chelsea;
      case 'Crystal Palace':
        return CrystalPalace;
      case 'Everton':
        return Everton;
      case 'Fulham':
        return Fulham;
      case 'Ipswich':
        return Ipswich;
      case 'Leicester':
        return Leicester;
      case 'Liverpool':
        return Liverpool;
      case 'Luton':
        return Luton;
      case 'Manchester City':
        return ManCity;
      case 'Manchester United':
        return ManUnited;
      case 'Newcastle':
        return Newcastle;
      case 'Nottingham Forest':
        return NottinghamForest;
      case 'Sheffield United':
        return SheffieldUnited;
      case 'Southampton':
        return Southampton;
      case 'Tottenham':
        return Tottenham;
      case 'West Ham':
        return WestHam;
      case 'Wolves':
        return Wolves;
      default:
        return null;
    }
  };

  return (
    <div className=''>
      <div className='App-headerV3'>
        <Navbar />
        <h2 className='text-center text-dark padtop mb-1'>EPL Standings</h2>
        <hr className="w-40 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
        <div className='container text-center mb-2'><i className='text-center'>*Click on team name to view stats </i>
</div>
<div className='d-flex justify-content-center'>
      <div className='col-6 col-md-3 text-center'>
        <select 
          className='form-select mb-3 text-center' 
          style={{ backgroundColor: 'purple', color: 'white' }}
          value={season} 
          onChange={(e) => setSeason(e.target.value)}
        >
          <option value="2024" style={{ backgroundColor: 'white', color: 'black' }}>EPL 24/25</option>
          <option value="2023" style={{ backgroundColor: 'white', color: 'black' }}>EPL 23/24</option>
          <option value="2022" style={{ backgroundColor: 'white', color: 'black' }}>EPL 22/23</option>
          <option value="2021" style={{ backgroundColor: 'white', color: 'black' }}>EPL 21/22</option>
          <option value="2020" style={{ backgroundColor: 'white', color: 'black' }}>EPL 20/21</option>
        </select>
      </div>
    </div>



        <div className='container'>
          {loading ? (
            <h2 className='text-center text-dark'><Spinner /></h2>
          ) : (
            <table className="table table table-striped" style={{ backgroundColor: '' }}>
              <thead className='' style={{ backgroundColor: '', color: 'black' }}>
                <tr>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>#</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>Team</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>MP</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>W</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>D</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>L</th>
                  <th scope="col" style={{ backgroundColor: '', color: 'black' }}>Pts</th>
                </tr>
              </thead>
              <tbody >
                {standings.map((team, index) => (
                  <tr key={index}>
                    <th scope="row" className={index < 4 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 4 ? 'text-warning' :  index == 5 ? 'text-warning' :  index == 6 ? 'text-success' : ''} style={{ backgroundColor: '', color: 'black' }}>{index + 1}</th>
                    <td style={{ backgroundColor: '', color: 'black' }}>
                    {getTeamKit(team.team) && (
                        <img 
                          src={getTeamKit(team.team)} 
                          alt={`${team.team} Kit`} 
                          style={{ width: '25px', marginRight: '5px' }} 
                        />
                      )}
                      {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'black' }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Arsenal' ? (
                        <Link to="/arsenal" style={{ textDecoration: 'none', color: 'black' }}>Arsenal</Link>
                      ) : team.team === 'Manchester City' ? (
                        <Link to="/mancity" style={{ textDecoration: 'none', color: 'black' }}>Man City</Link>
                      ) : team.team === 'Liverpool' ? (
                        <Link to="/liverpool" style={{ textDecoration: 'none', color: 'black' }}>Liverpool</Link>
                      ) : team.team === 'Aston Villa' ? (
                        <Link to="/astonvilla" style={{ textDecoration: 'none', color: 'black' }}>Aston Villa</Link>
                      ) : team.team === 'Tottenham' ? (
                        <Link to="/tottenham" style={{ textDecoration: 'none', color: 'black' }}>Tottenham</Link>
                      ) : team.team === 'Chelsea' ? (
                        <Link to="/chelsea" style={{ textDecoration: 'none', color: 'black' }}>Chelsea</Link>
                      ) : team.team === 'Newcastle' ? (
                        <Link to="/newcastle" style={{ textDecoration: 'none', color: 'black' }}>Newcastle</Link>
                      ) : team.team === 'West Ham' ? (
                        <Link to="/westham" style={{ textDecoration: 'none', color: 'black' }}>West Ham</Link>
                      ) : team.team === 'Crystal Palace' ? (
                        <Link to="/crystalpalace" style={{ textDecoration: 'none', color: 'black' }}>Crystal Palace</Link>
                      ) : team.team === 'Bournemouth' ? (
                        <Link to="/bournemouth" style={{ textDecoration: 'none', color: 'black' }}>Bournemouth</Link>
                      ) : team.team === 'Brighton' ? (
                        <Link to="/brighton" style={{ textDecoration: 'none', color: 'black' }}>Brighton</Link>
                      ) : team.team === 'Everton' ? (
                        <Link to="/everton" style={{ textDecoration: 'none', color: 'black' }}>Everton</Link>
                      ) : team.team === 'Fulham' ? (
                        <Link to="/fulham" style={{ textDecoration: 'none', color: 'black' }}>Fulham</Link>
                      ) : team.team === 'Wolves' ? (
                        <Link to="/wolves" style={{ textDecoration: 'none', color: 'black' }}>Wolves</Link>
                      ) : team.team === 'Brentford' ? (
                        <Link to="/brentford" style={{ textDecoration: 'none', color: 'black' }}>Brentford</Link>
                      ) : team.team === 'Nottingham Forest' ? (
                        <Link to="/nottinghamforest" style={{ textDecoration: 'none', color: 'black' }}>Nott. Forest</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'black' }}>Luton</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/burnley" style={{ textDecoration: 'none', color: 'black' }}>Burnley</Link>
                      ) : team.team === 'Sheffield Utd' ? (
                        <Link to="/sheffieldutd" style={{ textDecoration: 'none', color: 'black' }}>Sheffield</Link>
                      ) : team.team === 'Leicester' ? (
                        <Link to="/Leicester" style={{ textDecoration: 'none', color: 'black' }}>Leicester</Link>
                      ) : team.team === 'Ipswich' ? (
                        <Link to="/Ipswich" style={{ textDecoration: 'none', color: 'black' }}>Ipswich</Link>
                      ) : team.team === 'Southampton' ? (
                        <Link to="/Southampton" style={{ textDecoration: 'none', color: 'black' }}>Southampton</Link>
                      ) : (
                        team.team
                      )}
                    </td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.matchesPlayed}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.wins}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.draws}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.losses}</td>
                    <td style={{ backgroundColor: '', color: 'black' }}>{team.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className='d-flex justify-content-center'>
            <div className='text-center text-primary'>| </div>
            <div className='text-dark'>UEFA Champions League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-warning'>| </div>
            <div className='text-dark'>Europa League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-success'>| </div>
            <div className='text-dark'>Europa Conference League Qualification</div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center text-danger'>| </div>
            <div className='text-dark'>Relegation</div>
          </div>
        </div>
        <div className='container'>
          <div className="text- mt-4" style={{ paddingBottom: '5px' }}>
            <button className="btn btn-outline-dark" onClick={handleGoBack}>Go Back</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Standings;
