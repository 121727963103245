import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import Monaco from '../Team-Kits-Images/Monaco.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Benfica from '../Team-Kits-Images/Benfica.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Brest from '../Team-Kits-Images/Brest.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Brugge from '../Team-Kits-Images/Brugge.png'
import Zagreb from '../Team-Kits-Images/Zagreb.png'
import Feyenoord from '../Team-Kits-Images/Feyenoord.png'
import Girona from '../Team-Kits-Images/Girona.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Lille from '../Team-Kits-Images/Lille.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import PSG from '../Team-Kits-Images/PSG.png'
import PSV from '../Team-Kits-Images/PSV.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Belgrade from '../Team-Kits-Images/Belgrade.png'
import Salzburg from '../Team-Kits-Images/Salzburg.png'
import Shakhtar from '../Team-Kits-Images/Shakhtar.png'
import Bratislava from '../Team-Kits-Images/Bratislava.png'
import Praha from '../Team-Kits-Images/Prague.png'
import Sporting from '../Team-Kits-Images/Sporting.png'
import Graz from '../Team-Kits-Images/Graz.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import YoungBoys from '../Team-Kits-Images/YoungBoys.png'

import { useTranslation } from 'react-i18next';
import Standings from './Standings'
import { runTransaction } from 'firebase/firestore'


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import UclStandingsPicks from './UclStandingsPicks'
 
function UCLPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');

  const fetchTotalPoints = async (displayName) => {
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `UCLTotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `UCLPicks/${displayName}`);
      const totalPointsRef = ref(db, `UCLTotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      // Start point incrementing intervals for the fetched picks
      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-09-15', end: '2024-09-20' },  // Matchweek 1
    { start: '2024-09-20', end: '2024-10-03' },  // Matchweek 2
    { start: '2024-10-03', end: '2024-10-24' },  // Matchweek 3
    { start: '2024-10-24', end: '2024-11-07' },  // Matchweek 4
    { start: '2024-11-07', end: '2024-11-28' },  // Matchweek 5
    { start: '2024-11-28', end: '2024-12-12' },  // Matchweek 6
    { start: '2024-12-12', end: '2025-01-23' },  // Matchweek 7
    { start: '2025-01-23', end: '2025-01-30' }   // Matchweek 8
    // Add more matchweeks as needed
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  


useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const fixtureRef = ref(db, `fixtures/${fixtureId}`);
    const fixtureSnapshot = await get(fixtureRef);
    const fixtureData = fixtureSnapshot.val();

    console.log("Fixture Data:", fixtureData); // Debug: Log fixture data

    if (fixtureData && fixtureData.fixture.status.short === "FT") {
      const homeGoals = fixtureData.score.fulltime.home;
      const awayGoals = fixtureData.score.fulltime.away;

      const picksRef = ref(db, `UCLPicks`);
      const picksSnapshot = await get(picksRef);
      const allUserPicks = picksSnapshot.val() || {};

      Object.keys(allUserPicks).forEach(async (userId) => {
        const userPicks = allUserPicks[userId];
        const userPick = userPicks[fixtureId];

        if (userPick && !userPick.pointsAwarded) {
          const { home: predictedHomeGoals, away: predictedAwayGoals } = userPick;

          let points = 0;

          if (predictedHomeGoals == homeGoals && predictedAwayGoals == awayGoals) {
            points = 3;
          } else if (
            (predictedHomeGoals > predictedAwayGoals && homeGoals > awayGoals) ||
            (predictedHomeGoals < predictedAwayGoals && homeGoals < awayGoals) ||
            (predictedHomeGoals == predictedAwayGoals && homeGoals == awayGoals)
          ) {
            points = 1;
          }

          console.log(`Awarding ${points} points to user ${userId} for fixture ${fixtureId}`); // Debug: Log points awarded

          userPicks[fixtureId] = {
            ...userPick,
         
          };

          const userTotalPointsRef = ref(db, `UCLTotalPoints/${userId}`);
          const userTotalPointsSnapshot = await get(userTotalPointsRef);
          const userTotalPoints = userTotalPointsSnapshot.val() || { totalPoints: 0 };

          await set(userTotalPointsRef, {
            totalPoints: userTotalPoints.totalPoints + points,
          });

          await set(ref(db, `UCLPicks/${userId}`), userPicks);
        }
      });
    }
  } catch (error) {
    console.error("Error awarding points:", error);
  }
};



const startPointsIncrement = (fixtureId) => {
  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000); // Check every 60 seconds
};

const stopPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
    delete intervalRefs.current[fixtureId];
  }
};

// Ensure that this function is called every 60 seconds to check for completed fixtures
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);

const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
  
      }
    }));
  };

  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `UCLPicks/${displayName}`);
      const totalPointsRef = ref(db, `UCLTotalPoints/${displayName}`);
  
      let userDisplayName = user.displayName || 'Anonymous';
  
      // Extract user ID within parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }
  
      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};
  
        // Debug: Log the current inputsData before filtering
        console.log("inputsData before filtering:", inputsData);
  
        // Filter inputsData to only include fixtures where a valid prediction is made
        const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
          const fixturePrediction = inputsData[fixtureId];
  
          // Check if home or away prediction is made (0 is valid, undefined is not)
          if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
            acc[fixtureId] = {
              home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
              away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
            };
          }
          return acc;
        }, {});
  
        // Debug: Log the filtered inputsData after filtering
        console.log("filteredInputsData:", filteredInputsData);
  
        // Check if any predictions are available for submission
        if (Object.keys(filteredInputsData).length === 0) {
          console.log("No predictions made, nothing to submit.");
          return;  // Exit early if there are no valid predictions
        }
  
        // Merge new filtered picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...filteredInputsData
        };
  
        // Debug: Log the merged data being sent to the backend
        console.log("Merged picks data being submitted:", mergedPicks);
  
        // Submit the merged picks to the backend
        await set(picksRef, mergedPicks);
  
        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }
  
        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);
  
        // Start points incrementing for all fixtures with a prediction
        Object.keys(filteredInputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };
  
  
  

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Bayern München': BayernMunich,
    'Aston Villa': AstonVilla,
    'Liverpool': Liverpool,
    'Juventus': Juventus,
    'Atletico Madrid': Athleti,
    'Real Madrid': Madrid,
    'Sporting CP': Sporting,
    'Arsenal': Arsenal,
    'Atalanta': Atalanta,
    'Barcelona': Barcelona,
    'Bayer Leverkusen': Leverkusen,
    'Benfica': Benfica,
    'Bologna': Bologna,
    'Borussia Dortmund': Dortmund,
    'Stade Brestois 29': Brest,
    'Celtic': Celtic,
    'Club Brugge KV': Brugge,
    'Feyenoord': Feyenoord,
    'Girona': Girona,
    'Inter': Inter,
    'Manchester City': ManCity,
    'Monaco': Monaco,
    'Paris Saint Germain': PSG,
    'RB Leipzig': Leipzig,
    'FK Crvena Zvezda': Belgrade,
    'Red Bull Salzburg': Salzburg,
    'Shakhtar Donetsk': Shakhtar,
    'Slovan Bratislava': Bratislava,
    'Sparta Praha': Praha,
    'Sturm Graz': Graz,
    'PSV Eindhoven': PSV,
    'VfB Stuttgart': Stuttgart,
    'AC Milan': Milan,
    'Lille': Lille,
    'BSC Young Boys': YoungBoys,
    'Dinamo Zagreb': Zagreb,



   
  };
  
  const teamInitials = {
    'Bayern München': 'BAY',
    'Aston Villa': 'AVFC',
    'Liverpool': 'LFC',
    'Juventus': 'JUV',
    'Atletico Madrid': 'ATM',
    'Real Madrid': 'RM',
    'Sporting CP': 'SCP',
    'Arsenal': 'AFC',
    'Atalanta': 'ATL',
    'Barcelona': 'BAR',
    'Bayer Leverkusen': 'B04',
    'Benfica': 'SLB',
    'Bologna': 'BFC',
    'Borussia Dortmund': 'BVB',
    'Stade Brestois 29': 'SB29',
    'Celtic': 'CEL',
    'Club Brugge KV': 'BRU',
    'Feyenoord': 'FEY',
    'Girona': 'GIR',
    'Inter': 'INT',
    'Manchester City': 'MCFC',
    'Monaco': 'ASM',
    'Paris Saint Germain': 'PSG',
    'RB Leipzig': 'RBL',
    'FK Crvena Zvezda': 'CRV',
    'Red Bull Salzburg': 'RBS',
    'Shakhtar Donetsk': 'FCD',
    'Slovan Bratislava': 'SLB',
    'Sparta Praha': 'SPA',
    'Sturm Graz': 'STU',
    'PSV Eindhoven': 'PSV',
    'VfB Stuttgart': 'VFB',
    'AC Milan': 'ACM',
    'Lille': 'LOSC',
    'BSC Young Boys': 'YB',
    'Dinamo Zagreb': 'DIN',


   
  };
  
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  return (
    <div>
    <Navbar />
    <div className='App-headerV3'>
      <h2 className='text-dark text-center padtop'>UCL Picks</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
                  <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                    Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}
          <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin' style={{ paddingTop: '15px' }}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'><UclStandingsPicks /></h5>
              </div>
            </div>
          </div>
  
          {/* Main content area */}
          <div className='col-lg-7 col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
  
              let points = 0;
  
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
  
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card'>
                  <div className='card-body text-center mb-1'>
                    <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                    <div className="row align-items-center">
                      <div className="col text-center d-flex flex-column align-items-center">
                        {renderTeamLogo(fixture.teams.home.name)}
                        <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                      </div>
                      <div className="col text-center padtopinput border-secondary">
                        <div className='form-group border-secondary text-center'>
                          <select
                            value={inputsData[fixture.fixture.id]?.home ?? ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (!isNaN(value) && value >= 0 && value <= 10) {
                                handleInputChange(fixture.fixture.id, 'home', value);
                              } else {
                                handleInputChange(fixture.fixture.id, 'home', '');
                              }
                            }}
                            className='form-control text-center border-secondary'
                            disabled={fixture.fixture.status.short !== 'NS'}
                            style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
                          >
                            <option value="">Pick</option>
                            {[...Array(11).keys()].map(num => (
                              <option key={num} value={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col text-center padtopinput border-secondary">
                        <div className='form-group border-secondary text-center'>
                          <select
                            value={inputsData[fixture.fixture.id]?.away ?? ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (!isNaN(value) && value >= 0 && value <= 10) {
                                handleInputChange(fixture.fixture.id, 'away', value);
                              } else {
                                handleInputChange(fixture.fixture.id, 'away', '');
                              }
                            }}
                            className='form-control text-center border-secondary'
                            disabled={fixture.fixture.status.short !== 'NS'}
                            style={{ textAlign: 'center', textAlignLast: 'center' }} // Inline styles for centering text
                          >
                            <option value="">Pick</option>
                            {[...Array(11).keys()].map(num => (
                              <option key={num} value={num}>{num}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col text-center d-flex flex-column align-items-center">
                        {renderTeamLogo(fixture.teams.away.name)}
                        <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                      </div>
                    </div>
                    <div>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
                    <p className="text-center">{convertToFormattedDateTime(fixture.fixture.date)}</p>
  
                    {/* Display the points scored for this fixture */}
                    {fixture.fixture.status.short === 'FT' && (
                      <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : ''}>
                        You scored <b>{points}</b> {points === 1 ? 'point' : 'points'} for this game
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p>Total points: <b>{totalPoints}</b></p>
            </div>
  
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                  Your picks have been sent!
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>Error submitting picks!</p>}
          </div>
  
          {/* Standings Card for small screens */}
          <div className='col-12 d-lg-none mb-3' style={{ marginTop: '15px' }}>
            <div className='card'>
              <div className='card-body'>
                <h5 className='card-title'><UclStandingsPicks /></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  
  );
}

export default UCLPicks;
