import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Sundowns from '../Team-Kits-Images/Sundowns.png'
import Pirates from '../Team-Kits-Images/Pirates.png'
import Arrows from '../Team-Kits-Images/Arrows.png'
import Polokwane from '../Team-Kits-Images/Polokwane.png'
import Richards from '../Team-Kits-Images/Richards.png'
import Kaizer from '../Team-Kits-Images/Kaizer.png'
import Sekh from '../Team-Kits-Images/Sekh.png'
import Chippa from '../Team-Kits-Images/Chippa.png'
import Royal from '../Team-Kits-Images/Royal.png'
import Cape from '../Team-Kits-Images/Cape.png'
import Supersport from '../Team-Kits-Images/Supersport.png'
import Amazulu from '../Team-Kits-Images/Amazulu.png'
import Galaxy from '../Team-Kits-Images/Galaxy.png'
import Stell from '../Team-Kits-Images/Stell.png'
import Magesi from '../Team-Kits-Images/Magesi.png'
import Marumo from '../Team-Kits-Images/Margumo.png'
import { Spinner } from 'react-bootstrap';
import HomeTeamFixtures from './Home.Team.Fixtures';


function MarumoStats() {
  const [teamStats, setTeamStats] = useState(null);
  const [nextFixture, setNextFixture] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/288/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 2682);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 2682 || fixture.teams.away.id === 2682;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  const form = teamStats?.form || '';

  // Convert the form string into an array of characters
  const formArray = form.split('');

  // Get the last 5 matches from the form array
  const lastFiveMatches = formArray.slice(-5);

  const getClassForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'success'; // Corrected class name to Bootstrap's 'success'
      case 'D':
        return 'warning'; // Corrected class name to Bootstrap's 'warning'
      case 'L':
        return 'danger'; // Corrected class name to Bootstrap's 'danger'
      default:
        return '';
    }
  };

const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };
  const teamLogos = {
    'Mamelodi Sundowns': Sundowns,
    'Orlando Pirates': Pirates,
    'Golden Arrows': Arrows,
    'Polokwane City': Polokwane,
    'Richards Bay': Richards,
    'Kaizer Chiefs': Kaizer,
    'Sekhukhune United': Sekh,
    'Chippa United': Chippa,
    'Royal AM': Royal,
    'Cape Town City': Cape,
    'Supersport United': Supersport,
    'Amazulu': Amazulu,
    'TS Galaxy': Galaxy,
    'Stellenbosch': Stell,
    'Magesi': Magesi,
    'Marumo Gallants': Marumo
};
  
const teamInitials = {
    'Mamelodi Sundowns': 'MSFC',
    'Orlando Pirates': 'OPFC',
    'Golden Arrows': 'GAFC',
    'Polokwane City': 'PFC',
    'Richards Bay': 'RBFC',
    'Kaizer Chiefs': 'KCFC',
    'Sekhukhune United': 'SUFC',
    'Chippa United': 'CUFC',
    'Royal AM': 'RAM',
    'Cape Town City': 'CTC',
    'Supersport United': 'SSU',
    'Amazulu': 'AFC',
    'TS Galaxy': 'TSG',
    'Stellenbosch': 'SFC',
    'Magesi': 'MFC',
    'Marumo Gallants': 'MGFC'
};

  
  return (
    <div>
    <Navbar />
    <div className='App-headerV3'>
      {teamStats ? (
        <section className="bg-transparent py-3 py-md-5 py-xl-8">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <h2 className="mb-4 display-5 text-center text-dark">{teamStats.name} Team Stats</h2>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>
  
          <div className="container">
            <div className="row gy-4 gy-lg-0">
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="row gy-4">
                  <div className="col-12">
                    <div className="card widget-card border-light shadow-sm">
                      <h6 className="card-header text-bg-success text-center">{teamStats.name} Home Kit</h6>
                      <div className="card-body">
                        <div className="text-center mb-3">
                          <img src={teamLogos[teamStats.name]} style={{ maxWidth: '100px', maxHeight: '100px' }} alt={`${teamStats.name} Logo`} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card widget-card border-light shadow-sm">
                      <h6 className="card-header text-bg-success text-center">About {teamStats.name}</h6>
                      <div className="card-body">
                        <h5 className="text-center mb-1">Manager</h5>
                        <p className="text-center text-secondary mb-4">Dylan Kerr                    </p>
                        <h5 className="text-center mb-1">Stadium</h5>
                        <p className="text-center text-secondary mb-4">Peter Mokaba Stadium
                        </p>
                        <h5 className="text-center mb-1">Year Founded</h5>
                        <p className="text-center text-secondary mb-4">2021  


                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div className="col-12">
                    <div className="card widget-card border-light shadow-sm">
                      <h6 className="card-header text-bg-success text-center">{teamStats.name}'s Next SA Soccer Fixture</h6>
                      <div className="card-body">
                        {nextFixture ? (
                          <div>
                            <div className='d-flex text-center'>
                              <b className="text-center mb-1 col-5">
                                {nextFixture.teams.home.name in teamLogos ? (
                                  <>
                                    <img src={teamLogos[nextFixture.teams.home.name]} alt={nextFixture.teams.home.name} style={{ width: '75px', height: 'auto' }} />
                                    <br /> <span>{teamInitials[nextFixture.teams.home.name]}</span>
                                  </>
                                ) : (
                                  <span>{nextFixture.teams.home.name}</span>
                                )}
                              </b>
                              <h5 className="text-center mb-1 col-2 padtopstats">VS</h5>
                              <b className="text-center mb-1 col-5">
                                {nextFixture.teams.away.name in teamLogos ? (
                                  <>
                                    <img src={teamLogos[nextFixture.teams.away.name]} alt={nextFixture.teams.away.name} style={{ width: '75px', height: 'auto' }} />
                                    <br /> <span>{teamInitials[nextFixture.teams.away.name]}</span>
                                  </>
                                ) : (
                                  <span>{nextFixture.teams.away.name}</span>
                                )}
                              </b>
                            </div>
                            <p className='text-center text-secondary mb-4'>{convertToFormattedDateTime(nextFixture.fixture.date)}</p>
                          </div>
                        ) : (
                          <p className='text-center text-secondary'>No upcoming fixture</p>
                        )}
                        <h5 className="text-center mb-1">Last 5 SA Soccer Matches</h5>
                        <p className="text-center text-secondary mb-1">
                          {lastFiveMatches.map((result, index) => (
                            <span key={index}>
                              <span className={`badge bg-${getClassForMatch(result)} me-1`}>{result}</span>
                            </span>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              {/* Main Section */}
              <div className="col-12 col-lg-8 col-xl-9">
                      <div className="card widget-card border-light shadow-sm">
                          <div className="card-body p-4">
                              <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Scoring Stats</button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <button className="nav-link" id="fun-tab" data-bs-toggle="tab" data-bs-target="#fun-tab-pane" type="button" role="tab" aria-controls="fun-tab-pane" aria-selected="false">Fun Stats</button>
                                  </li>
                              </ul>
                              <div className="tab-content pt-4" id="profileTabContent">
                                  <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                      {/* Content for Overview tab */}
                                      <h5 className="mb-3">SA Soccer Games</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.played && teamStats.fixtures.played.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Won</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Drawn</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.draws && teamStats.fixtures.draws.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Games Lost</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.loses && teamStats.fixtures.loses.total}</div>
                                              </div> 
                                              </div>
                                              <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Away Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.away} </div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Home Wins</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.home} </div>
                                              </div> 
                                      </div>
                                      <h5 className="mb-3">Notable Results</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Win</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.away}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Home Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Biggest Away Loss</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.away}</div>
                                              </div> 
                                      </div>

                                  </div>

                                  <div className="tab-pane fade" id="fun-tab-pane" role="tabpanel" aria-labelledby="fun-tab" tabIndex="2">
  {/* Content for Other Stats tab */}
  <h5 className="mb-3">Interesting Statistics</h5>

  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Yellow Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.yellow && Object.values(teamStats.cards.yellow).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Red Cards</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
        {teamStats.cards && teamStats.cards.red && Object.values(teamStats.cards.red).reduce((acc, cur) => acc + cur.total, 0)}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Scored</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.total}
      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Total Pentalties Missed</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.missed && teamStats.penalty.missed.total}

      </div>
    </div> 
  </div>
  <div className="row g-0">
    <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
      <div className="p-2">Penalty Goal Rate</div>
    </div>
    <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
      <div className="p-2">
      {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.percentage}
      </div>
    </div> 
  </div>
  <i>*note these statistics are only for the SA Soccer</i>

</div>



                                  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                      {/* Content for Other Stats tab */}
                                      <h5 className="mb-3">Scoring Statistics</h5>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored At Home</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.home}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Goals Scored Away</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.away}</div>
                                              </div> 
                                      </div>
                 
                                      
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Scored Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Average Goals Conceded Per Game</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.goals && teamStats.goals.against && teamStats.goals.against.average.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Clean Sheets</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.clean_sheet && teamStats.clean_sheet.total}</div>
                                              </div> 
                                      </div>
                                      <div className="row g-0">
                                      <div className="col-6 col-md-4 bg-light border-bottom border-white border-3">
                                              <div className="p-2">Total Games Without Scoring</div>
                                              </div>
                                              <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3">
                                              <div className="p-2">{teamStats.failed_to_score && teamStats.failed_to_score.total}</div>
                                              </div> 
                                              <i>*note these statistics are only for the SA Soccer</i>

                                      </div>
                                    
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              {/* End of Main Section */}
            </div>
          </div>
  
          <div className='container'>
            <div className="text-center mt-4" style={{paddingBottom: '5px'}}>
              <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
            </div>
          </div>
        </section>
      ) : (
        <h2 className='text-center'><Spinner /></h2>
      )}
    </div>
    <Footer />
  </div>
  
  );
}
// {teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}
export default MarumoStats
